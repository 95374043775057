import { store } from "src/store";
import { Root } from "./mailDataProps";
import { getAirportName } from "src/views/helpers";
import moment from "moment";

export const createMailTemplate = (travelers: any[], formData: Root) => {
  const model = store?.getState()?.searchModel;

  const airportCityDictionaries =
    store?.getState()?.locations?.cityAirportDictionaries;
  const booking = store?.getState()?.flights?.booking;

  const billingDetail = formData?.billingDetailModel;

  const from = booking?.itineraries[0]?.segments[0];
  const to =
    booking?.itineraries[0]?.segments[
      booking?.itineraries[0]?.segments?.length - 1
    ];

  const body = `<html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
    <title>Mobile Responsive Email</title>
    
    <style type="text/css">
        body{
            font-family: 'Inter', sans-serif;
        }
    th, td, p, a {
            font-family: 'Inter', sans-serif;
            font-size:18px;
    }
    th {
        font-weight: 500;
        text-align: left;
        width: 20%;
    }
    td {
        font-weight: 300;
        font-size: 17px;
    }
    table {
        width: 1270px;
    }
    tr.titelbg th {
        padding: 10px 10px;
        background: #185791;
        color: #fff;
    }
    td, th {
        padding: 9px 9px;
    }
    
    td {
        border-bottom: 0;
    }
    tr.traveler th {
        border: none;
        background: #e8e8e8;
    }
    .logoplane span {
        display: flex;
        gap: 10px;
        align-content: center;
        align-items: center;
    }
    </style>
    
    </head>
    </body>
            <table style="margin: auto;border-spacing: 0px 1px;">
                <tr class="titelbg">
                    <th colspan="5">Booking Details:</th>
                </tr>
                <tr class="tagname1">
                    <th colspan="2">Departing from</th>
                    <th colspan="2">Arriving at</th>
                </tr>
                <tr class="tagdata1">
                    <td colspan="2">${
                      getAirportName(
                        airportCityDictionaries,
                        from?.departure?.iataCode
                      )?.fullName
                    }</td>
                    <td colspan="2">${
                      getAirportName(
                        airportCityDictionaries,
                        to?.arrival?.iataCode
                      )?.fullName
                    }</td>
                </tr>
                
                <tr class="tagdata1">
                    <td colspan="2">${moment(from?.departure?.at as any).format(
                      "MM/DD/YYYY"
                    )}</td>
                    <td colspan="2">${moment(to?.arrival?.at as any).format(
                      "MM/DD/YYYY"
                    )}</td>
                </tr>
                
                <tr class="tagdata1">
                    <td colspan="2">${moment(from?.departure?.at as any).format(
                      "HH:mm"
                    )}</td>
                    <td colspan="2">${moment(to?.arrival?.at as any).format(
                      "HH:mm"
                    )}</td>
                </tr>
            
    <!--Contact info -->
                <tr class="titelbg">
                    <th colspan="5">1. Contact Info</th>
                </tr>
                <tr class="tagname">
                    <th colspan="2">Name</th>
                    <th colspan="2">Email</th>
                </tr>
                <tr class="tagdata">
                    <td colspan="2">${billingDetail.fullName}</td>
                    <td colspan="2">${billingDetail?.email}</td>
                </tr>
                <tr class="tagname">			
                    <th colspan="2">Phone</th>
                </tr>
                
                <tr class="tagdata">
                    <td colspan="2">${billingDetail?.phoneNumber}</td></td>
                </tr>
    <!--End Contact info -->
                
        
    <!--Travellers info -->		
    
                <tr class="titelbg">
                    <th colspan="5">2. Travellers Info</th>
                </tr>
                <tr class="tagname">
                    <th colspan="1">Adults</th>
                    <th colspan="1">Children</th>
                    <th colspan="1">Infants</th>
                </tr>
                <tr class="tagdata">
                    <td colspan="1">${model?.adults}</td>
                    <td colspan="1">${model?.children}</td>
                    <td colspan="1">${model?.infants}</td>
                </tr>
    
    <!--Travellers info -->                
            </table>
            
            
    </body>
    </html>`;

  return body;
};

import { Box, Container, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "src/components/Breadcrumb";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";

const Component = () => {
  const location = useLocation();
  const [vacation, setVacation] = useState(null);
  const theme = useTheme();
  const pathname = location?.pathname;
  const vacationUrl = pathname?.substring(pathname?.lastIndexOf("/") + 1);

  const [data, setData] = useState([]);
  useEffect(() => {
    fetch("/vacation.json")
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error("Failed to fetch data");
      })
      .then((responseData) => {
        setData(responseData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const foundVacation = data?.find((ele) => ele?.url === vacationUrl);
    if (foundVacation) {
      setVacation(foundVacation);
    } else {
      console.error(`Vacation with URL "${vacationUrl}" not found.`);
    }
  }, [data, vacationUrl]);

  const title = vacation?.title?.split(":");

  return (
    <>
      <Box>
        <Helmet>
          <title>{vacation?.metatitle}</title>
          <meta name="description" content={vacation?.description} />
          <meta property="og:title" content={vacation?.metatitle} />
          <meta name="keywords" content={vacation?.keywords} />
          <meta name="theme-color" content={theme?.colors?.secondary?.main} />
        </Helmet>
        <Breadcrumbs
          title={vacation?.breadcrum}
         
          banner={{
            md: "/assets/img/vacations-banner.webp",
            xs: "/assets/img/vacations-banner-mobile.webp",
          }}
          
        />
        <Container sx={{ my: 2, mb: 6 }} maxWidth="lg">
          <SectionHeader
            sx={{
              color: theme?.colors?.primary?.main,
              fontWeight: "bold",
              textAlign: "start",
            }}
            component="h1"
          >
            {title?.length > 1 && (
              <HeaderSpan sx={{ color: theme?.colors?.secondary?.main }}>
                {title?.slice(1, title?.length)?.join(" ")}
              </HeaderSpan>
            )}
          </SectionHeader>

          <Box
            component="div"
            sx={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: vacation?.content }}
          />
          <Typography
            sx={{ width: "100%" }}
            component="img"
            src={vacation?.img}
          />
          <Box
            component="div"
            sx={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: vacation?.content1 }}
          />
        </Container>
      </Box>
    </>
  );
};

export default Component;

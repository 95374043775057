import Modal from "src/components/Modal";
import { reducerProps } from "src/interfaces";
import { connect } from "react-redux";
import { HowToBook } from "../components";


const Component = ({
  visible,
  setVisible,
}: {
  visible: boolean;
  setVisible;
}) => {
  return (
    <Modal
      sx={{
        width: { xl: "35%", lg: "40%", md: "55%" },
        mx: "auto",
        display: "flex",
        justifyContent: "center",
      }}
      open={visible}
      onClose={() => setVisible(false)}
    >
      <HowToBook />
    </Modal>
  );
};

const mapStateToProps = ({}: reducerProps) => ({});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

import { routeProps } from '../../interfaces';
import { SET_CURRENT_ROUTE, REMOVE_ROUTE } from './types';

export const setCurrentRoute = (payload: routeProps) => {
  return {
    type: SET_CURRENT_ROUTE,
    payload
  };
};
export const removeRoute = () => {
  return {
    type: REMOVE_ROUTE
  };
};

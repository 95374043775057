import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import Card from "../../../components/Card";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import PaperIcon from "src/components/PaperIcon";
import { cards } from "./constants";
import VacationCard from "./VacationCard";
import { useEffect, useState } from "react";

const Vacations = () => {
  const theme = useTheme();
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/vacation.json').then(res => {
      if (res.status === 200) {
        res.json().then(responseData => {
          setData(responseData)
        })
      }
    })
  }, [])
  return (
    <SectionContainer sx={{ mt: "4rem", mb: { md: "5rem" } }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <SectionHeader sx={{ textAlign: "center" }}>
          We don't just <HeaderSpan>BOOK VACATION PACKAGES</HeaderSpan>
          <Box
            sx={{
              fontSize: 16,
              fontWeight: "600",
              color: theme?.colors?.alpha?.black[70],
            }}
          >
            We craft unforgettable adventures
          </Box>
        </SectionHeader>
        <Box sx={{ width: "100%" }}>
          <Typography component="p" sx={{ textAlign: "center" }}>
            Explore our variety of vacation packages in our dedicated section.
            If you don't see a package that fits your needs, contact us
            directly. We'll craft a customized vacation package tailored to your
            travel preferences, budget, and group size at the best prices.
          </Typography>
        </Box>
        <Stack
          direction={{ md: "row" }}
          justifyContent={{ md: "space-between", xs: "center" }}
          alignItems="center"
          flexWrap={"wrap"}
          sx={{
            mt: { md: 2.5, xs: 2 },
            gap: { md: 3, xs: 0 },
          }}
        >
          {cards?.map((item: any, index) => {
            return (
              <VacationCard
                key={index}
                item={{
                  ...item,
                }}
                index={index}
              />
            );
          })}
        </Stack>
      </Container>
      <PaperIcon src="/assets/icons/paper-icon-2.svg" sx={{ left: 0 }} />
    </SectionContainer>
  );
};

export default Vacations;

import * as React from "react";
import { Card as DefaultCard, SxProps, useTheme } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const Card = ({
  children,
  title,
  titleEnd,
  description,
  icon,
  sx,
  imgSx,
  contentSx,
  titleSx,
  descriptionSx,
  alt="",
}: {
  children?: any;
  title?: string | React.ReactNode;
  titleEnd?: string | React.ReactNode;
  description?: string | React.ReactNode;
  icon?: any;
  sx?: SxProps;
  imgSx?: SxProps;
  contentSx?: SxProps;
  titleSx?: SxProps;
  descriptionSx?: SxProps;
  alt?: string;
}) => {
  const theme = useTheme();
  return (
    <DefaultCard
      sx={{
        textAlign: "center",
        padding: 1,
        width: { md: "30%", xs: "100%" },
        boxShadow: `1px 1px 4px ${theme.colors.secondary.main}`,
        ...sx,
      }}
    >
      <CardMedia
        component="img"
        sx={{ height: 60, width: 60, mx: "auto", mb: 0.5, ...imgSx }}
        image={icon}
        alt={alt}
      />
      {!children && (title || description) && (
        <CardContent sx={{ p: 0, pb: "0 !important", ...contentSx }}>
          {title && (
            <Typography gutterBottom variant="h4" component="div" sx={titleSx}>
              {title}
              {titleEnd}
            </Typography>
          )}
          {description && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={descriptionSx}
            >
              {description}
            </Typography>
          )}
        </CardContent>
      )}
      {children}
    </DefaultCard>
  );
};

export default Card;

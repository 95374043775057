import { flightSearchProps, reviewsRoot } from "src/interfaces";
import {
  FETCH_GOOGLE_REVIEWS,
  RESET_SEARCH_MODEL,
  RESET_TEMP_MODEL,
  SEND_ENQUIRY_MAIL,
  SET_ACTIVE_TAB,
  SET_FILTER_MODAL,
  SET_GOOGLE_REVIEWS,
  SET_SEARCH_MODEL,
  SET_SHOW_DETAILS,
  SET_TEMP_MODEL,
} from "./types";

export const setSearchModel = (payload: flightSearchProps) => {
  return {
    type: SET_SEARCH_MODEL,
    payload,
  };
};

export const resetSearchModel = () => {
  return {
    type: RESET_SEARCH_MODEL,
  };
};

export const setTempModel = (payload: flightSearchProps) => {
  return {
    type: SET_TEMP_MODEL,
    payload,
  };
};

export const resetTempModel = () => {
  return {
    type: RESET_TEMP_MODEL,
  };
};

export const setFilterModal = (payload: boolean) => {
  return {
    type: SET_FILTER_MODAL,
    payload: payload,
  };
};
export const setActiveTab = (payload: "price" | "time" | "price&time") => {
  return {
    type: SET_ACTIVE_TAB,
    payload: payload,
  };
};
export const setShowDetails = (payload: number[]) => {
  return {
    type: SET_SHOW_DETAILS,
    payload: payload,
  };
};

export const fetchGoogleReview = () => {
  return {
    type: FETCH_GOOGLE_REVIEWS,
  };
};

export const setGoogleReview = (payload: reviewsRoot) => {
  return {
    type: SET_GOOGLE_REVIEWS,
    payload: payload,
  };
};

export const sendEnquiryMail = (payload: any, callback: Function) => {
  return {
    type: SEND_ENQUIRY_MAIL,
    payload: payload,
    callback,
  };
};

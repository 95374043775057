import HomeIcon from "@mui/icons-material/Home";
import FlightIcon from "@mui/icons-material/Flight";
import AboutIcon from "@mui/icons-material/Info";
import ContactIcon from "@mui/icons-material/ContactPage";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import BookIcon from "@mui/icons-material/Book";

type SidebarMenuObject = {
  title?: string;
  path?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  children?: SidebarMenuObject[];
};

export const menus: SidebarMenuObject[] = [
  {
    title: "Home",
    path: "/",
    startIcon: <HomeIcon />,
  },
  {
    title: "Book Your Flight",
    path: "/flights",
    startIcon: <FlightIcon />,
  },
  {
    title: "Vacations",
    path: "/vacations",
    startIcon: <HolidayVillageIcon />,
  },
  {
    title: "About Us",
    path: "/about-us",
    startIcon: <AboutIcon />,
  },
  {
    title: "Blog",
    path: "/blog",
    startIcon: <BookIcon />,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    startIcon: <ContactIcon />,
  },
];

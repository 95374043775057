import React from "react";
import { Box, Stack, SxProps, Typography, useTheme } from "@mui/material"; 

const Airline = ({
  logo,
  name,
  containerSx,
  iconSx,
  nameSx,
}: {
  logo: string;
  name?: string | React.ReactNode;
  containerSx?: SxProps;
  iconSx?: SxProps;
  nameSx?: SxProps;
}) => {
  const theme = useTheme();
  return (
    <Stack direction="row" sx={{ alignItems: "center", ...containerSx }}>
      <Box
        component="img"
        src={logo}
        sx={{
          width: 25,
          objectFit: "contain",
          mr: name ? 0.5 : 0,
          ...iconSx,
        }}
      />
      {name && (
        <Typography sx={{ color: theme?.colors?.alpha?.gray[100], ...nameSx }}>
          {name}
        </Typography>
      )}
    </Stack>
  );
};

export default Airline;

import { loaderProps } from "../../interfaces";
import { SET_LOADER, REMOVE_LOADER } from "./types";

export const setLoader = (payload: loaderProps) => {
  return {
    type: SET_LOADER,
    payload: {
      ...payload,
      visible: true,
      type: payload?.type ? payload.type : "main",
    } as loaderProps,
  };
};
export const removeLoader = () => {
  return {
    type: REMOVE_LOADER,
  };
};

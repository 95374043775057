import { Box, Container } from "@mui/material";
import { connect } from "react-redux";
import SectionHeader, { HeaderSpan } from "../../../components/SectionHeader";
import { flightSearchProps, reducerProps } from "src/interfaces";
import SearchArea from "../../components/searchArea";

const SearchFlight = ({ tempModel }: { tempModel: flightSearchProps }) => {
  const isRoundTrip = tempModel?.type === "round";

  return (
    <Box
      sx={{
        backgroundImage: `url(${"/assets/img/search-header-bg.webp"})`,
        backgroundSize: "cover",
        top: { md: -120, xs: -100 },
        mb: { xs: isRoundTrip ? 18 : 10, md: -4 },
        height: { md: 750, xs: 650 },
        width: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          position: "absolute",
          top: { md: 220, xs: 200 },
        }}
      >
        <SectionHeader
          component="h1"
          sx={{
            fontSize: { md: 60, xs: 28 },
            maxWidth: { md: "55%" },
            color: "#233580",
            textAlign: "start",
            lineHeight: 1.2,
            marginTop: { md: 5, xs: 2 },
            fontWeight: "bold",
            fontDisplay:"swap",
          }}
        >
          Find Your Flights<br></br> At <HeaderSpan>Your Budget</HeaderSpan>
        </SectionHeader>
        {/* <PrimaryButton sx={{ borderRadius: 0 }}>More Info</PrimaryButton> */}
        <Box sx={{ marginTop: { md: 25, xs: 3 } }} />
        <SearchArea />
      </Container>
    </Box>
  );
};

const mapStateToProps = ({ tempModel }: reducerProps) => ({ tempModel });
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(SearchFlight);

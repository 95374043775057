import { cityAirportData } from "src/interfaces";
import { fetch } from "./http";
import { getUniqueListBy } from "src/helpers";

const controller: string = "v1/reference-data/";

export const fetchAirportsCities = async (keyword = "", token: string) => {
  try {
    const response = await fetch.Get(
      controller +
        `locations?subType=AIRPORT,CITY&keyword=${keyword}&page%5Blimit%5D=10&page%5Boffset%5D=0&sort=analytics.travelers.score&view=FULL`,
      token
    );
    if (response.status === 200) {
      let responseData: any = await response.json();
      let list: cityAirportData[] = responseData?.data;
      list = getUniqueListBy(list, "iataCode");
      const finalResponse = { ...responseData, data: list };
      return finalResponse;
    } else if (response.status === 201) {
      const data = await response.json();
      throw new Error(data);
    } else if (response?.status != 429 && response?.status != 401) {
      throw new Error("Something went wrong");
    }
  } catch (error) {
    throw error;
  }
};

export const fetchAirportCityDetail = async (code: string, token: string) => {
  try {
    const response = await fetch.Get(controller + `locations/${code}`, token);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
  } catch (error) {}
};

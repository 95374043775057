import { Box, Stack, Typography, useTheme } from "@mui/material";
import Card from "src/components/Card";
import { cards } from "./constants";
import SectionContainer from "../../../components/SectionContainer";
import Banner from "src/components/Banner";
import SectionHeader, {
  HeaderSpan,
  SectionHeaderTop,
} from "../../../components/SectionHeader";
import WhyChooseCard from "./WhyChooseCard";

const WhyChooseUs = () => {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ mb: "0 !important" }}>
      <Banner
        url="/assets/img/why-choose-bg.webp"
        colorSx={{
          backgroundImage: "unset",
        }}
      >
        <Stack direction={{ md: "row" }} spacing={2}>
          <Box width={{ md: "80%" }}>
            <SectionHeaderTop
              sx={{
                textAlign: "start",
                color: theme?.colors?.alpha?.black[100],
              }}
            >
              <HeaderSpan>Why</HeaderSpan> Choose Us
            </SectionHeaderTop>
            <SectionHeader
              sx={{
                textAlign: "start",
                color: theme?.colors?.alpha?.black[100],
                textTransform: "none",
              }}
            >
              Book <HeaderSpan>Your Flight</HeaderSpan> at a{" "}
              <HeaderSpan>Reasonable Price</HeaderSpan>
            </SectionHeader>
            <Box sx={{ width: "100%" }}>
              <Typography
                component="p"
                sx={{
                  textAlign: "justify",
                  color: theme?.colors?.alpha?.black[100],
                }}
              >
                Book cheap air tickets with the best travel agency in Calgary &
                Edmonton. With expert guidance, exclusive deals, personalized
                service, and a commitment to making your travel dreams a
                reality, we are your path to unforgettable adventures.
              </Typography>
            </Box>
            <Stack
              direction={"row"}
              flexWrap="wrap"
              justifyContent={{ md: "space-between", xs: "space-around" }}
              rowGap={2}
              mt={2}
            >
              {cards.map((item, index) => (
                <WhyChooseCard key={index} {...item} />
              ))}
            </Stack>
          </Box>
          <Card
            icon={"/assets/img/whychooseus-graphics.webp"}
            alt="best travel company"
            imgSx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            sx={{
              mt: {
                md: 0,
                xs: 2,
              },
              boxShadow: "unset",
              width: { md: "20%", xs: "100%" },
              padding: 0,
              backgroundColor: "transparent",
            }}
          />
        </Stack>
      </Banner>
    </SectionContainer>
  );
};

export default WhyChooseUs;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Snackbar as DefaultSnackbar } from "@mui/material";
import { alertProps } from "../../interfaces";
import { removeAlert } from "../../store";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Snackbar = ({
  alert,
  removeAlert,
}: {
  alert: alertProps;
  removeAlert: Function;
}) => {
  const autoHideDuration = 6000;
  const [alertData, setAlertData] = useState<alertProps>(null);
  useEffect(() => {
    if (alert?.visible) {
      setAlertData(alert);
      removeAlert();
      setTimeout(() => {
        setAlertData(null);
      }, autoHideDuration);
    }
  }, [alert?.visible]);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertData(null);
    removeAlert();
  };

  return (
    alertData?.text && (
      <DefaultSnackbar
        anchorOrigin={alertData?.anchorOrigin}
        open={alertData?.visible}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={alertData?.type || "success"}
          sx={{ width: "100%" }}
        >
          {alertData?.text}
        </Alert>
      </DefaultSnackbar>
    )
  );
};
const mapStateToProps = ({ alert }) => ({ alert });
const mapDispatchToProps = (dispatch) => ({
  removeAlert: () => dispatch(removeAlert()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);

import { alertProps } from '../../interfaces';
import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert = (payload: alertProps) => {
  return {
    type: SET_ALERT,
    payload: { ...payload, visible: true }
  };
};
export const removeAlert = () => {
  return {
    type: REMOVE_ALERT
  };
};

import type { PayloadAction } from '@reduxjs/toolkit';
import { SET_CURRENT_ROUTE, REMOVE_ROUTE } from './types';
import { routeProps } from '../../interfaces';

const initialeState: routeProps = {
  path: null,
  params: null
};
export const route = (
  state = initialeState,
  action: PayloadAction<routeProps> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_CURRENT_ROUTE: {
      return payload;
    }
    case REMOVE_ROUTE: {
      return initialeState;
    }
    default: {
      return state;
    }
  }
};

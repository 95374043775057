import { Stack, useTheme } from "@mui/material";
import Card from "../../../components/Card";
import { cards } from "./constants";
import SectionContainer from "src/components/SectionContainer";
import Banner from "src/components/Banner";
import CountUp from "react-countup";
import { useLocation } from "react-router";

const Counters = () => {
  const theme = useTheme();
  const location = useLocation();
  const isHome = location?.pathname === "/";
  return (
    <SectionContainer sx={{}}>
      <Banner
        url={
          isHome
            ? "/assets/img/counter-bg.webp"
            : "/assets/img/about-us/counter-bg.webp"
        }
        colorSx={{ backgroundImage: "unset", mt: isHome ? 3 : 0 }}
      >
        <Stack
          direction={{ md: "row", xs: "row" }}
          flexWrap="wrap"
          justifyContent={{ md: "space-between", xs: "space-around" }}
          alignItems="center"
          spacing={{ md: 2, xs: 0 }}
          rowGap={{ xs: 2, md: 0 }}
          sx={{
            ".MuiPaper-root": {
              cursor: "pointer",
              position: "relative",
              transition: "all 0.5s",
              bottom: 0,
              ":hover": {
                transition: "all 0.5s",
                bottom: 5,
                boxShadow: `1px 1px 35px ${theme.colors.secondary.light}`,
              },
            },
          }}
        >
          {cards(isHome).map((item, index) => (
            <Card
              key={index}
              {...item}
              title={<CountUp end={item?.max} />}
              titleEnd={item?.titleEnd}
              titleSx={{ fontWeight: "bold", fontSize: { md: 30, xs: 18 } }}
              descriptionSx={{
                fontWeight: "bold",
                color: "black",
                fontSize: { md: 18, xs: 14 },
              }}
              imgSx={{
                objectFit: "contain",
                width: "40%",
                height: "40%",
                mb: { md: 0, xs: 0.5 },
              }}
              sx={{
                mb: { md: 0, xs: 2 },
                backgroundColor: theme?.colors?.alpha?.white[50],
                height: { lg: 250, md: 200, xs: 150 },
                width: { lg: 250, md: 200, xs: 150 },
                borderRadius: 2.7,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            />
          ))}
        </Stack>
      </Banner>
    </SectionContainer>
  );
};

export default Counters;

import { Box, Stack, Typography, useTheme } from "@mui/material";
import Card from "src/components/Card";

const imgHeight = 320;
const DealCard = ({ item, index, onClick }) => {
  const theme = useTheme();
  return (
    <Card
      {...item}
      key={index}
      sx={{
        mb: { md: 2, xs: 2 },
        padding: 0,
        borderRadius: 0,
        position: "relative",
      }}
      imgSx={{ width: "100%", height: imgHeight }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height={imgHeight}
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Box
          onClick={onClick}
          sx={{
            display: "flex",
            width: "50%",
            cursor: "pointer",
            height: imgHeight / 2.5,
            backgroundColor: theme?.colors?.alpha?.black[70],
            color: theme?.colors?.alpha?.white[100],
            position: "relative",
            transition: "all 0.5s",
            ":hover": {
              transition: "all 0.5s",
              backgroundColor: theme.colors.alpha.secondary[50],
            },
          }}
        >
          <Box m={"auto"}>
            <Typography>{item?.originName}</Typography>
            <Typography
              src="/assets/icons/arrow-down.webp"
              component="img"
              sx={{
                width: 20,
                position: "relative",
                objectFit: "contain",
              }}
            />
            <Typography>{item?.destinationName}</Typography>
          </Box>
        </Box>
      </Stack>
      <Stack
        direction="row"
        width="100%"
        sx={{ position: "relative", marginTop: -5.5 }}
      >
        <Box
          sx={{
            width: "40%",
            height: "100%",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme?.colors[item?.backgroundColor]?.main,
          }}
        >
          <Box sx={{ px: 1, py: 0, alignItems: "center", display: "flex" }}>
            <Box
              component="h4"
              sx={{ lineHeight: 0.9, ml: 0.5, fontWeight: "500" }}
            >
              Search Flight
            </Box>
          </Box>
        </Box>
        <Box
          component="span"
          sx={{
            position: "relative",
            width: "60%",
            mt: "auto",
            lineHeight: 0,
            top: 1.5,
            backgroundColor: theme?.colors?.alpha?.white[100],
            textAlign: "start",
          }}
        >
          <Typography
            src="/assets/icons/multi-colors.svg"
            component="img"
            sx={{
              width: "80%",
              height: "100%",
              position: "relative",
              objectFit: "contain",
            }}
          />
        </Box>
      </Stack>
    </Card>
  );
};

export default DealCard;

import { Box } from "@mui/material";
import Breadcrumbs from "src/components/Breadcrumb";
import Vacations from "./Vacations";
import GoogleReviews from "../components/googleReviews";
import Subscribe from "../components/Subscribe";
import Faqs from "../components/faqs";
import { faqsVacations } from "../contactUs/constants";

const Home = () => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs
          title={["Popular", "Vacation Deals"]}
          banner={{
            md: "/assets/img/vacations-banner.webp",
            xs: "/assets/img/vacations-banner-mobile.webp",
          }}
        />
        <Vacations />
        <GoogleReviews />
        <Faqs faqs={faqsVacations} />
        <Subscribe />
      </Box>
    </>
  );
};

export default Home;

import {
  FETCH_CITY_AIRPORT_DICTIONARIES,
  REMOVE_CITY_AIRPORT_DICTIONARIES,
  REMOVE_SEARCH_CITIES,
  SEARCH_CITIES,
  SET_CITY_AIRPORT_DICTIONARIES,
  SET_SEARCH_CITIES,
  SET_SEARCH_CITIES_LOADER,
} from "./types";

export const fetchAirportsCities = (payload: any) => {
  return {
    type: SEARCH_CITIES,
    payload,
  };
};
export const setSearchCities = (payload: {
  key: "origins" | "destinations";
  cities: any[];
  loading?: boolean;
}) => {
  return {
    type: SET_SEARCH_CITIES,
    payload,
  };
};
export const removeSearchCities = (key: "origins" | "destinations") => {
  return {
    type: REMOVE_SEARCH_CITIES,
    payload: key,
  };
};

export const setSearchCitiesLoader = (loading: boolean) => {
  return {
    type: SET_SEARCH_CITIES_LOADER,
    payload: loading,
  };
};

export const fetchCityAirportDictionaries = (payload: {
  codes: string[];
  type: "A" | "C";
}) => {
  return {
    type: FETCH_CITY_AIRPORT_DICTIONARIES,
    payload,
  };
};

export const setCityAirportDictionaries = (payload: any) => {
  return {
    type: SET_CITY_AIRPORT_DICTIONARIES,
    payload,
  };
};

export const removeCityAirportDictionaries = () => {
  return {
    type: REMOVE_CITY_AIRPORT_DICTIONARIES,
  };
};

import MailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AddressIcon from "@mui/icons-material/Map";

export const contactInfos = [
  {
    title: ["Calgary", " Office"],
    info: [
      {
        icon: <MailIcon color="primary" sx={{ mr: 1 }} />,
        label: "calgary@millwoodstravels.ca",
        value: "mailto:calgary@millwoodstravels.ca",
      },
      {
        icon: <PhoneIcon color="primary" sx={{ mr: 1 }} />,
        label: "+1 403-687-0111",
        value: "tel:+1 403-687-0111",
      },
      {
        icon: <AddressIcon color="primary" sx={{ mr: 1 }} />,
        value:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.675185315574!2d-113.95187292303306!3d51.132899137992396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537163f1ee07eaff%3A0x3ca019ec5a04d3a9!2sMillwoods%20Travels!5e0!3m2!1sen!2sin!4v1702969160281!5m2!1sen!2sin",
        address: true,
        label: "5850 88 Ave NE, #6120,Calgary, AB T3J 0Z7",
      },
    ],
  },
  {
    title: ["Edmonton ", " Office"],
    info: [
      {
        icon: <MailIcon color="primary" sx={{ mr: 1 }} />,
        label: "edmonton@millwoodstravels.ca",
        value: "mailto:edmonton@millwoodstravels.ca",
      },
      {
        icon: <PhoneIcon color="primary" sx={{ mr: 1 }} />,
        label: "+1 780-244-3222",
        value: "tel:+1 780-244-3222",
      },
      {
        icon: <AddressIcon color="primary" sx={{ mr: 1 }} />,
        value:
          "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2375.6215625351874!2d-113.36957882292671!3d53.45735086641155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a019905de3a0bf%3A0x7c7e678efb2566dd!2s2543%2017%20St%20NW%2C%20Edmonton%2C%20AB%20T6T%200Y2%2C%20Canada!5e0!3m2!1sen!2sin!4v1729521696740!5m2!1sen!2sin",
        address: true,
        label: "2543 17th St NW, Edmonton AB T6T 0Y2",
      },
    ],
  },
];

export const faqsFlights = [
  {
    question: "What is the best time to book a flight for the lowest fares?",
    answer: `Booking in advance and being flexible with your travel dates can help you find the best fares. Consider using price comparison tools for better deals.`,
  },
  {
    question: "How do I request special assistance or accommodations?",
    answer: `You can directly contact the airline or specify your needs to our travel agents in Calgary and Edmonton during the booking process. Airlines can assist passengers with disabilities or special requirements.`,
  },
  {
    question: "What happens if my flight is delayed or cancelled?",
    answer: `Airlines have policies for handling delays and cancellations. Check with the airline for information on rebooking, compensation, or accommodation in such situations. You can also contact our customer support by calling our toll-free number in case of additional information.`,
  },
  {
    question: "Can I change my flight instead of cancelling to avoid fees?",
    answer: `You can adjust your flight date; however, please note that a change fee applies. Additionally, fees associated with the new date will be applicable upon rescheduling. For detailed information on terms and conditions in the event of cancellation, kindly reach out to our travel agents.`,
  },
];

export const faqsVacations = [
  {
    question: "How do I search for vacation packages?",
    answer: `Explore our variety of vacation packages in our dedicated section. If you don't see a package that fits your needs, contact us directly. We'll craft a customized vacation package tailored to your travel preferences, budget, and group size at the best prices.`,
  },
  {
    question: "Can I book a vacation package for a group of people?",
    answer: `Certainly! You can book cheap vacation packages for a group of people, allowing everyone to share the excitement of the experience. When booking, provide the total number of travellers in your group, and our system will help ensure accommodations and activities can accommodate the specified group size.`,
  },
  {
    question:
      "How do I access my vacation package itinerary and booking confirmation?",
    answer: `All your booking details, including a detailed itinerary, booking specifics, a detailed price breakdown, and terms and conditions, will be sent to your registered email address. If you have any inquiries, feel free to reach out to our customer support executives for assistance.`,
  },
  {
    question: "What types of vacation packages are available with Millwoods?",
    answer: `Millwoods offers both cheap family vacation packages as well as solo tour vacation packages. Whether you seek the relaxation of all-inclusive resorts, the thrill of adventure getaways, or the cultural richness of city escapes, we have the perfect package for you. Our options also include beach retreats for seaside relaxation and family-friendly packages with activities for all ages. For those seeking a romantic escape, our packages offer intimate accommodations and spa experiences.`,
  },
];

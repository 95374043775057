import type { PayloadAction } from "@reduxjs/toolkit";
import {
  SET_AUTH_USER,
  REMOVE_AUTH_USER,
  SET_AMADEUS_TOKEN,
  REMOVE_AMADEUS_TOKEN,
} from "./types";
import { authUserProps } from "src/interfaces";

const initialeState: authUserProps = null;
export const auth = (
  state = initialeState,
  action: PayloadAction<any> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_AUTH_USER: {
      return { ...state, ...payload };
    }
    case REMOVE_AUTH_USER: {
      return { ...initialeState, token: state?.token };
    }
    case SET_AMADEUS_TOKEN: {
      return { ...state, ...payload };
    }
    case REMOVE_AMADEUS_TOKEN: {
      return { ...state, ...payload, token: "" };
    }
    default: {
      return state;
    }
  }
};

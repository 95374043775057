import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_ALERT, REMOVE_ALERT } from "./types";
import { alertProps } from "../../interfaces";

const initialeState: alertProps = {
  text: "",
  type: "",
  visible: false,
  anchorOrigin: { vertical: "top", horizontal: "right" },
};
export const alert = (
  state = initialeState,
  action: PayloadAction<alertProps> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_ALERT: {
      return { ...state, ...payload };
    }
    case REMOVE_ALERT: {
      return initialeState;
    }
    default: {
      return state;
    }
  }
};

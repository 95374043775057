import { Box, Stack, Typography, useTheme } from "@mui/material";

const WhyChooseCard = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{ width: { md: "45%", xs: "100%" } }}
      direction="row"
      alignItems="flex-start"
    >
      <Typography
        component="img"
        src="/assets/icons/whychoose-bullet-icon.svg"
        sx={{ mr: 1, width: 40, objectFit: "contain" }}
      />
      <Box>
        <Typography variant="h3" color={theme?.colors?.alpha?.black[100]}>
          {title}
        </Typography>
        <Typography component="p" color={theme?.colors?.alpha?.black[70]}>
          {description}
        </Typography>
      </Box>
    </Stack>
  );
};

export default WhyChooseCard;

export const SET_FILTER_MODAL = "SET_FILTER_MODAL";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_SHOW_DETAILS = "SET_SHOW_DETAILS";
export const SET_SEARCH_MODEL = "SET_SEARCH_MODEL";
export const RESET_SEARCH_MODEL = "RESET_SEARCH_MODEL";
export const SET_TEMP_MODEL = "SET_TEMP_MODEL";
export const RESET_TEMP_MODEL = "RESET_TEMP_MODEL";
export const FETCH_GOOGLE_REVIEWS = "FETCH_GOOGLE_REVIEWS";
export const SET_GOOGLE_REVIEWS = "SET_GOOGLE_REVIEWS";
export const SEND_ENQUIRY_MAIL = "SEND_ENQUIRY_MAIL";

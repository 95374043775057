import { taxCountryCode } from "src/config";
import {
  cityAirportDictionaries,
  itineraryProps,
  locationProps,
  segmentProps,
} from "src/interfaces";

export const getCanadaToCanada = (
  segments: segmentProps[],
  locations: locationProps = {}
) => {
  let status = false;
  const startSegment = segments[0];
  const lastSegment = segments[segments?.length - 1];

  status =
    locations[startSegment?.departure?.iataCode]?.countryCode ===
      taxCountryCode &&
    locations[lastSegment?.departure?.iataCode]?.countryCode === taxCountryCode;
  return status;
};

export const getAirportName = (
  cityAirportDictionaries: cityAirportDictionaries,
  iataCode: string
) => {
  const cityAirportData = cityAirportDictionaries["A" + iataCode]?.data;
  let fullName: string = `${
    cityAirportDictionaries["A" + iataCode]?.data?.name || iataCode
  } Airport (${iataCode})`;
  if (!fullName?.includes(cityAirportData?.address?.cityName)) {
    fullName = `${cityAirportData?.address?.cityName || ""} ${fullName}`;
  }
  return {
    ...cityAirportData,
    fullName: fullName,
  };
};

export const getCarriers = (itineraries: itineraryProps[]) => {
  let carrierCodes = [];
  itineraries?.map((item) => {
    const carrierCode = item?.segments?.map((subItem) => subItem?.carrierCode);
    carrierCodes = [...new Set([...carrierCodes, ...carrierCode])];
  });
  return carrierCodes;
};

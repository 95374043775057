import {
  Box,
  BoxProps,
  Container,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useAppSelector } from "src/store";
import "./style.css";

export const Column = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        maxWidth: { md: "25%", xs: "100%" },
        width: { md: "25%", xs: "100%" },
        mr: 2,
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

export const Stops = ({
  stops,
  sx,
  stackSx,
  spacing = 10,
}: {
  stops: number;
  sx?: SxProps;
  stackSx?: SxProps;
  spacing?: number;
}) => {
  const theme = useTheme();
  const numberOfStops: any[] = [];
  for (let i = 0; i < stops; i++) {
    numberOfStops.push(
      <Box
        key={i}
        sx={{
          width: 10,
          minWidth: 10,
          height: 10,
          minHeight: 10,
          borderRadius: 10,
          backgroundColor: theme?.colors?.secondary?.main,
          position: "relative",
          ...sx,
        }}
      />
    );
  }
  return (
    <Stack
      sx={{
        postion: "absolute",
        mt: -0.6,
        width: `${spacing * stops * stops}%`,
        alignItems: "center",
        mx: "auto",
        justifyContent: false ? "center" : "space-between",
        ...stackSx,
      }}
      direction="row"
    >
      {numberOfStops}
    </Stack>
  );
};

export const FilterSectionTitle = ({ title }: { title?: any }) => {
  const theme = useTheme();
  return (
    <Box sx={{ maxWidth: "50%", position: "relative" }}>
      <Box
        sx={{
          color: theme?.colors?.alpha?.white[100],
          px: 1.5,
          py: 0.5,
          backgroundColor: theme?.colors?.secondary?.main,
          mr: { xs: -0.001, md: 0 },
        }}
      >
        {title}
      </Box>
      <Typography
        component="span"
        className="triangle"
        style={{ borderLeftColor: theme?.colors?.secondary?.main }}
      />
    </Box>
  );
};

export const FilterSectionSubTitle = ({ title }: { title?: any }) => {
  const theme = useTheme();
  return (
    <Box sx={{ color: theme?.colors?.secondary?.main, mb: 0.5 }}>{title}</Box>
  );
};

export const FlightPagination = ({
  count = 1,
  page = 1,
  setPage,
}: {
  count: number;
  page: number;
  setPage?: Function;
}) => {
  return (
    <Stack sx={{ position: "absolute", bottom: 0 }} spacing={2}>
      <Pagination
        count={count}
        page={page}
        onChange={(e, value) => {
          window?.scrollTo(0, 0);
          setPage(value);
        }}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
          />
        )}
      />
    </Stack>
  );
};

export const HowToBook = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme?.colors?.secondary?.main,
        py: { md: 5, xs: 2.5 },
        px: { md: 3, xs: 1 },
        mx: "auto",
        border: `2px solid ${theme?.colors?.alpha?.white[100]}`,
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            borderRadius: 10,
            py: 1,
            textAlign: "center",
            mx: "auto",
            backgroundColor: "#309CFF",
            color: "white",
            mb: 2,
            fontSize: { md: 25, xs: 16 },
          }}
        >
          How to book your flight?
        </Box>
        <Typography
          component="img"
          src={"/assets/icons/how-to-book.svg"}
          sx={{ width: "100%", mt: 3 }}
        />
      </Container>
    </Box>
  );
};

export const NoFlights = ({ mt = 0 }: { mt?: number | string }) => {
  const searching = useAppSelector((state) => state?.tempModel?.searching);
  if (searching) {
    return (
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "95%",
          fontWeight: "bold",
          mt: mt,
          py: { md: 15 },
        }}
      >
        <Box
          component="img"
          src="/assets/img/search-loader.gif"
          sx={{ width: { md: 200, xs: 150 }, objectFit: "contain" }}
        />
      </Box>
    );
  }
  if (searching === null) {
    return <HowToBook />;
  }
  return (
    <Box
      sx={{
        textAlign: "center",
        mt: { md: "30%", xs: "10%" },
        mb: { xs: "15%", md: "13%" },
        fontWeight: "bold",
        fontSize: 20,
      }}
    >
      No Flights
    </Box>
  );
};

export interface tabProps {
  title: string;
  subTitle: string;
  value: "price" | "time" | "price&time";
}

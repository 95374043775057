import {
  Box,
  Container,
  useTheme,
  TextField,
  ButtonProps,
  Typography,
  Stack,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import { PrimaryButton, PrimaryIconButton } from "src/components/Buttons";
import Modal from "src/components/Modal";
import TextArea from "src/components/TextArea";
import { inputs } from "./constants";
import { createFlightOrder } from "src/store";
import { reducerProps } from "src/interfaces";
import { connect } from "react-redux";

const Component = ({
  visible,
  setVisible,
  booking,
  createOrder,
}: {
  visible: boolean;
  setVisible;
  booking: any;
  createOrder: (payload: any) => void;
}) => {
  const theme = useTheme();
  const [model, setModel] = useState<any>({});

  const onChange = (event: any, type?: string) => {
    const name = event?.target?.name;
    let value: string = event?.target?.value;

    if (type === "numberOnly") {
      value = value?.replace(/[^0-9]/g, "");
    }
    if (type === "charOnly") {
      value = value?.replace(/[^A-Z,a-z," "]/g, "");
    }
    let newParams: any = {};
    if (name == "countryCode") {
      newParams = { stateName: "" };
    }
    setModel({
      ...model,
      [name]: value,
      ...newParams,
    });
  };

  const onSubmit = async (event) => {
    event?.preventDefault();
    createOrder({
      data: { type: "flight-order", flightOffers: [booking] },
      billingDetailModel: model,
    });
  };

  const callNowCalgoryIcon = (
    <Typography
      sx={{ width: "100%" }}
      component="img"
      src="/assets/icons/callnow-calgary.svg"
    />
  );

  const CallNowButtonCalgory = () => {
    return (
      <PrimaryButton
        variant="text"
        href="tel:+1 403-200-0222"
        sx={{
          p: "0 !important",
          mt: 0.5,
        }}
      >
        {callNowCalgoryIcon}
      </PrimaryButton>
    );
  };

  const callNowEdomontonIcon = (
    <Typography
      sx={{ width: "100%" }}
      component="img"
      src="/assets/icons/callnow-edmonton.svg"
    />
  );

  const CallNowButtonEdomonton = () => {
    return (
      <PrimaryButton
        variant="text"
        href="tel:+1 780-244-3222"
        sx={{ p: "0 !important", mt: 0.5 }}
      >
        {callNowEdomontonIcon}
      </PrimaryButton>
    );
  };

  return (
    <Modal
      sx={{
        width: { md: "35%" },
        mx: "auto",
        display: "flex",
        justifyContent: "center",
      }}
      open={visible}
      onClose={() => setVisible(false)}
    >
      <Box
        sx={{
          backgroundColor: theme?.colors?.secondary?.main,
          py: 5,
          px: 3,
          mx: "auto",
          border: `2px solid ${theme?.colors?.alpha?.white[100]}`,
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                color: "white",
                textAlign: "center",
                zIndex: 1,
                position: "relative",
                width: { lg: "20%", md: "30%", xs: "30%" },
                mx: "auto",
                backgroundColor: theme?.colors?.secondary?.main,
                fontSize: { md: 24, xs: 18 },
                mb: 2,
              }}
            >
              Call Us
            </Box>
          </Box>
          <Stack direction="row" width="100%" spacing={2} mt={1}>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ color: "#83C4FF" }}>Calgary Office</Typography>
              <CallNowButtonCalgory />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ color: "#83C4FF" }}>Edmonton Office</Typography>
              <CallNowButtonEdomonton />
            </Box>
          </Stack>
        </Container>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({
  flights: { booking },
  searchModel,
}: reducerProps) => ({
  model: searchModel,
  booking,
});

const mapDispatchToProps = (dispatch) => ({
  createOrder: (payload: any) => dispatch(createFlightOrder(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

import {
  Box,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  styled,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: "bold",
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      "0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100],
  },
}));

function Logo({ width = "" }: { width?: number | string }) {
  const theme = useTheme();

  return (
    <TooltipWrapper disableHoverListener title="Millwoods" arrow>
      <LogoWrapper to="/">
        <Box
          component="img"
          sx={{
            width: width,
            py: 1,
            height: { md: 60, xs: 50 },
            objectFit: "contain",
          }}
          alt="Millwoods Travels"
          src={"/assets/brand/logo.webp"}
        />
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;

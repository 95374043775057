import { Box, Stack, Typography, useTheme } from "@mui/material";
import Card from "src/components/Card";

const FaqCard = ({ item, index }) => {
  const theme = useTheme();
  return (
    <Card
      key={index}
      imgSx={{
        display: "none",
      }}
      sx={{
        mb: {
          md: 0,
          xs: "2rem !important",
        },
        boxShadow: "unset",
        width: { md: "45%" },
        height: 'auto',
        padding: { xs: 1.8, md: 1.6 },
        backgroundColor: theme?.colors?.alpha?.white[30],
      }}
      title={item?.question}
      titleSx={{
        textAlign: "start",
        textTransform: "capitalize",
        color: theme?.colors?.alpha?.black[100],
        fontSize: 22,
        mb: 3,
      }}
      description={item?.answer}
      descriptionSx={{
        textAlign: "start",
      }}
    />
  );
};

export default FaqCard;

import { Box, BoxProps as DefaultProps } from "@mui/material";

interface BoxProps extends DefaultProps {
  src: string;
}

const PaperIcon = (props: BoxProps) => {
  return (
    <Box
      src={props?.src}
      {...props}
      component="img"
      sx={{
        width: { md: "auto", xs: "70%" },
        position: "absolute",
        right: 0,
        top: -150,
        zIndex: 1,
        opacity: { md: 0.7, xs: 0.4 },
        ...props?.sx,
      }}
    />
  );
};

export default PaperIcon;

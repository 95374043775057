import { Box, Stack, useTheme } from "@mui/material";
import { PrimaryIconButton } from "src/components/Buttons";
import MinusIcon from "@mui/icons-material/Remove";
import PlusIcon from "@mui/icons-material/Add";

const PassengerMenuItem = ({
  title,
  subTitle,
  onMinusPress,
  onPlusPress,
  value,
  disabledMinus,
  disabledPlus,
}: {
  title: string;
  subTitle: string;
  onMinusPress: () => void;
  onPlusPress: () => void;
  value: any;
  disabledMinus: boolean;
  disabledPlus: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ mb: 1 }}
    >
      <Box>
        <Box component="h3" sx={{ color: "black", lineHeight: 0.3 }}>
          {title}
        </Box>
        <Box sx={{ color: "gray", lineHeight: 0 }}>{subTitle}</Box>
      </Box>
      <Stack direction="row" alignItems="center">
        <PrimaryIconButton
          onClick={() => {
            !disabledMinus && onMinusPress();
          }}
          sx={{
            backgroundColor: theme?.colors?.secondary?.main,
            borderRadius: 0,
            padding: 0.2,
            ":hover": {
              backgroundColor: theme?.colors?.secondary?.light,
            },
          }}
        >
          <MinusIcon
            fontSize="small"
            sx={{
              color: theme?.colors?.alpha?.white[100],
              borderColor: theme?.colors?.secondary?.main,
              borderWidth: 0,
              borderStyle: "solid",
            }}
          />
        </PrimaryIconButton>
        <Box sx={{ px: 0.7, color: "black" }}>{value}</Box>
        <PrimaryIconButton
          onClick={() => {
            !disabledPlus && onPlusPress();
          }}
          sx={{
            backgroundColor: theme?.colors?.secondary?.main,
            borderRadius: 0,
            padding: 0.2,
            ":hover": {
              backgroundColor: theme?.colors?.secondary?.light,
            },
          }}
        >
          <PlusIcon
            fontSize="small"
            sx={{
              color: theme?.colors?.alpha?.white[100],
              borderColor: theme?.colors?.secondary?.main,
              borderWidth: 0,
              borderStyle: "solid",
            }}
          />
        </PrimaryIconButton>
      </Stack>
    </Stack>
  );
};

export default PassengerMenuItem;

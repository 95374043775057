import { googleReviewsPlaceId } from "src/config";
import { fetch } from "./http";

export const googleReviews = async () => {
  try {
    const placeId = googleReviewsPlaceId;
    const response = await fetch.Get(
      `/api/google-reviews.php?placeId=${placeId}`,
      null,
      true
    );
    if (response.status === 200) {
      const data = await response?.json();
      return data;
    } else {
      throw new Error("Something went wrong");
    }
  } catch (error) { }
};

export const sendMail = async (
  mailBody: any,
  subject: string,
  mailto: string = null
) => {
  const mode = process.env.REACT_APP_MODE || ""
  const urlsByMode = {
    dev: "api/send-mail-dev.php",
    staging: "api/send-mail-dev.php",
    prod: "api/send-mail.php",
  }
  try {
    const response = await fetch.Post(
      urlsByMode[mode],
      { mailBody, subject, mailto },
      "multipart/form-data",
      null,
      "/"
    );
    if (response?.status != 200) {
      throw new Error("Server error");
    }
    return response;
  } catch (error) {
    throw error;
  }
};

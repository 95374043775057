import {
  Box,
  Container,
  Stack,
  SxProps,
  Typography,
  Link,
  useTheme,
} from "@mui/material";
import Card from "src/components/Card";
import { cards } from "./constants";
import { PrimaryButton } from "src/components/Buttons";
import SectionHeader, {
  HeaderSpan,
  SectionHeaderTop,
} from "../../../components/SectionHeader";
import SectionContainer from "../../../components/SectionContainer";

const WhyUnique = () => {
  const theme = useTheme();
  const DiscoverMore = ({ sx }: { sx: SxProps }) => {
    return (
      <PrimaryButton
        component={Link}
        href="/contact-us"
        sx={{
          mt: 1,
          width: { md: "25%" },
          textAlign: "center",
          ...sx,
        }}
      >
        Contact Us
      </PrimaryButton>
    );
  };

  return (
    <SectionContainer sx={{ mt: "4rem", mb: { md: "5rem", xs: "2.5rem" } }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          spacing={2}
        >
          <Card
            icon={"/assets/img/unique-graphics.webp"}
            alt="online travel agent"
            imgSx={{
              width: { md: "80%" },
              height: "100%",
              objectFit: "contain",
            }}
            sx={{
              mb: {
                md: 0,
                xs: "2rem !important",
              },
              zIndex: 9,
              boxShadow: "unset",
              width: { md: "40%" },
              padding: 0,
            }}
          />
          <Box width={{ md: "60%" }}>
            <SectionHeaderTop sx={{ textAlign: "start" }}>
              <HeaderSpan>Who</HeaderSpan> we are
            </SectionHeaderTop>
            <SectionHeader sx={{ textAlign: "start" }}>
              Why we are <HeaderSpan>Unique</HeaderSpan>
            </SectionHeader>
            <Box sx={{ width: "100%" }}>
              <Typography component="p" sx={{ textAlign: "justify" }}>
                We don't just book cheap flight tickets; we craft unforgettable
                adventures. Check flight schedules and contact us to book, we'll
                ensure every moment is a memorable one.
              </Typography>
            </Box>
            <DiscoverMore sx={{ display: { md: "none" } }} />
            <Stack
              direction="row"
              sx={{
                display: { md: "flex", xs: "block" },
                width: "100%",
                gap: { md: 2, xs: 0 },
                my: 2,
                ".MuiPaper-root": {
                  cursor: "pointer",
                  position: "relative",
                  transition: "all 0.5s",
                  bottom: 0,
                  ":hover": {
                    transition: "all 0.5s",
                    bottom: 5,
                    boxShadow: `1px 1px 25px ${theme.colors.secondary.light}`,
                  },
                },
              }}
            >
              {cards?.map((item, index) => (
                <Card
                  key={index}
                  {...item}
                  sx={{
                    mb: {
                      md: 0,
                      xs: 2,
                      backgroundColor: item?.backgroundColor,
                    },
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    height: {
                      lg: 200,
                      md: 180,
                      xs: document.body.clientWidth / 2,
                    },
                  }}
                />
              ))}
            </Stack>
            <DiscoverMore sx={{ display: { md: "block", xs: "none" } }} />
          </Box>
        </Stack>
      </Container>
    </SectionContainer>
  );
};

export default WhyUnique;

export * from 'src/common/constants'

export const inputs = [
  {
    key: "name",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    key: "email",
    label: "Email Address",
    type: "email",
    required: true,
  },
  {
    key: "phone",
    label: "Phone No.",
    type: "numberOnly",
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  {
    key: "message",
    label: "Message",
    type: "textarea",
    required: true,
  },
];

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-mwO_GzVM5TvtTMIoo8ips-4K3QDbW34",
  authDomain: "cyber-ace-flight-booking.firebaseapp.com",
  projectId: "cyber-ace-flight-booking",
  storageBucket: "cyber-ace-flight-booking.appspot.com",
  messagingSenderId: "567316776307",
  appId: "1:567316776307:web:2ba707bd40eaf0a952f106",
  measurementId: "G-V3B8FML82J",
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//export const analytics = getAnalytics(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);

export const firebaseDB = getFirestore(firebaseApp);

export default firebaseApp;

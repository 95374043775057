import { call, put, takeLatest } from "redux-saga/effects";
import { HANDLE_FETCH_AMADEUS_TOKEN } from "./types";
import { getAmadeusToken } from "../../services";
import { setAuthUser } from "./actions";
import { store } from "../";
import { differenceInSeconds } from "date-fns";

export function* handleFetchAmadeusToken(): Generator<any> {
  var token_time = store?.getState()?.auth?.token_time;
  var expires_in = store?.getState()?.auth?.expires_in;
  var token = store?.getState()?.auth?.access_token;
  const seconds = differenceInSeconds(new Date(), new Date(token_time));
  if (seconds < parseInt(expires_in)) {
    return token;
  }
  try {
    const responseData: any = yield call(getAmadeusToken);
    yield put(setAuthUser({ ...responseData, token_time: new Date() }));
    token = responseData?.access_token;
  } catch (error) {}
  return token;
}
export function* authSaga() {
  yield takeLatest<any>(HANDLE_FETCH_AMADEUS_TOKEN, handleFetchAmadeusToken);
}

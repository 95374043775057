import { Box, Container, Typography } from "@mui/material";
import Breadcrumbs from "src/components/Breadcrumb";
import SectionContainer from "src/components/SectionContainer";

const PrivacyPolicy = () => {
  return (
    <SectionContainer>
      <Breadcrumbs title={["Privacy", "Policy"]} />
      <Container maxWidth="lg" sx={{ mt: { md: 5, xs: 6 }, mb: 3 }}>
        <Box sx={{}}>
          <Box className="text-block">
            <Box component="h3">Information Collection</Box>
            <Typography component="p">
              When you share your business’s data, we want you to know it is
              protected. Millwoods, Inc. ("Millwoods"), the owner of
              www.Millwoods.com (the "Site"), are committed to protecting your
              privacy. Your Personal Information (ie. data that identifies you
              or from which you are identifiable) is collected for various
              purposes, including, but not limited to: responding to inquiries,
              processing applications, billing, communicating with you ("you,
              the traveler, or the "user"), submissions to our referral program,
              and submissions to our travel blog. We collect information from
              you when you place an order or subscribe to our newsletter.
            </Typography>
            <Typography component="p">
              We are committed to complying with applicable data protection
              laws. We offer personalized shopping experiences and advertising
              through Intent Media, which uses online identifiers to identify
              visitors. To view Intent Media’s privacy policy,{" "}
              <Box component="a" href="http://intentmedia.com/privacy-policy/">
                click here
              </Box>
              . To opt out,{" "}
              <Box component="a" href="http://intentmedia.com/opt-out/">
                click here
              </Box>
              . <Box component="br" /> Information Use{" "}
            </Typography>
            <Typography component="p">
              Personal Information relayed is used solely to facilitate,
              improve, and deliver the services we offer. We do not share your
              Personal Information with outside parties, except where necessary
              to process a request or inquiry from you, in an emergency
              situation, to enforce our policies, or where we are permitted to
              do so by applicable law, such as in response to a request by a law
              enforcement or governmental authority, in connection with actual
              or proposed litigation, or to protect our property, people and
              other rights. Any of the information we collect from you may be
              used in one of the following ways:
            </Typography>
            <Box component="ul">
              <Box component="li">
                To personalize your experience (your information helps us to
                better respond to your inBoxidual needs)
              </Box>
              <Box component="li">
                To improve our website (we continually strive to improve our
                website offerings based on the information and feedback we
                receive from you)
              </Box>
              <Box component="li">
                To improve customer service (your information helps us to more
                effectively respond to your customer service requests and
                support needs)
              </Box>
              <Box component="li">
                To process transactions: Your information, whether public or
                private, will not be sold, exchanged, transferred, or given to
                any other company for any reason whatsoever, without your
                consent, other than for the express purpose of delivering the
                purchased product or service requested.
              </Box>
              <Box component="li">
                To send periodic emails: The email address you provide for order
                processing, may be used to send you information and updates
                pertaining to your order, in addition to receiving occasional
                company news, updates, related product or service information,
                etc.
              </Box>
              <Box component="li">
                Marketing of Millwoods Services: We may use your data to market
                our services to you, as an existing client or prospective client
                entity, including to provide you with promotional materials or
                offers for products and services from Millwoods (including
                communications based on your interests, personal and business
                characteristics, and location). We may provide these
                communications and offers in various ways, including via email,
                postal mail, social media platforms, and text or direct
                messages.
              </Box>
            </Box>
            <Typography component="p">
              Note: If at any time you would like to unsubscribe from receiving
              future emails, we include detailed unsubscribe instructions at the
              bottom of each email.
            </Typography>
            <Box component="h3">
              Consent to Processing of Personal Information
            </Box>
            <Typography component="p">
              By providing any Personal Information to Millwoods, all users
              unambiguously consent to this Privacy Policy and to the transfer
              of such Personal Information between various states and countries
              in accordance with Millwoods’S standard operations as necessary to
              accomplish and carryout the operations of Millwoods. YOUR
              INFORMATION MAY BE TRANSFERRED TO, AND MAINTAINED ON, COMPUTERS
              AND SERVERS LOCATED OUTSIDE OF YOUR STATE, PROVINCE, COUNTRY OR
              OTHER GOVERNMENTAL JURISDICTION WHERE THE PRIVACY LAWS MAY NOT BE
              AS PROTECTIVE AS THOSE IN YOUR JURISDICTION. IF YOU ARE LOCATED
              OUTSIDE THE UNITED STATES AND CHOOSE TO PROVIDE INFORMATION TO US,
              MILLWOODS TRANSFERS PERSONAL INFORMATION TO THE UNITED STATES AND
              PROCESSES IT THERE. YOUR CONSENT TO THIS PRIVACY POLICY FOLLOWED
              BY YOUR SUBMISSION OF SUCH INFORMATION REPRESENTS YOUR AGREEMENT
              TO THAT TRANSFER.
            </Typography>
            <Box component="h3">Protecting Your Information</Box>
            <Typography component="p">
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your personal information.
            </Typography>
            <Typography component="p">
              We offer the use of a secure server. All supplied sensitive/credit
              information is transmitted via Secure Socket Layer (SSL)
              technology and then encrypted into our Payment gateway providers
              database only to be accessible by those authorized with special
              access rights to such systems, and are required to?keep the
              information confidential.
            </Typography>
            <Typography component="p">
              After a transaction, your private information (credit cards,
              financials, etc.) will not be stored on our servers.
            </Typography>
            <Box component="h3">
              Non-Personally-Identifiable Information We May Collect Online
            </Box>
            <Typography component="p">
              We may use various technologies to collect non-Personal
              Information about your visits online including: "Cookies" are
              small pieces of information that are stored by the user's browser
              on the user's computer's hard drive. Millwoods may use cookies to
              collect information during and about your visits to our Site, such
              as the particular site areas you visit. Cookies may also be used
              to recognize that you have provided Personal Information to us and
              refrain from requesting the same information a second time (e.g.,
              a username and/or password, or an e-mail address or other contact
              information). Most browsers are initially set to accept cookies,
              but can easily be set to refuse cookies. However, that refusal of
              cookies may limit your ability to take full advantage of all the
              Site features.
            </Typography>
            <Box component="ol">
              <Box component="li">Cookies.</Box>
              <Box component="li">Clear GIFs</Box>
            </Box>
            <Typography component="p">
              We may use pixel tags (also called clear gifs) or other similar
              tracking technologies in our websites and/or in our communications
              to collect information about your navigation on our websites, and
              to enable us to know whether you have visited a Web page or
              received a message. This information enables us to serve you and
              your interests better.
            </Typography>
            <Box component="h3">
              Do we disclose any information to outside parties?
            </Box>
            <Typography component="p">
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information. This does not include
              trusted third parties who assist us in operating our website,
              conducting our business, or servicing you, so long as those
              parties agree to keep this information confidential. We may also
              release your information when we believe release is appropriate to
              comply with the law, enforce our site policies, or protect ours or
              others rights, property, or safety. However, non-personally
              identifiable visitor information may be provided to other parties
              for marketing, advertising, or other uses.
            </Typography>
            <Box component="h3">Third-party website links</Box>
            <Typography component="p">
              This policy only addresses Millwoods's privacy policy concerning
              information collected from its own website and does not apply to
              information that may have been collected by others. This policy
              does not cover the privacy or information collection practices of
              third-party websites that may be linked to this website. Please be
              aware that when you leave this site, you should read the
              third-party website privacy practices before submitting Personal
              Information. Millwoods has no control or influence over
              third-party policies, and cannot be held liable for damage or
              misdoings of other websites linked or otherwise.
            </Typography>
            <Box component="h3">
              California Online Privacy Protection Act Compliance
            </Box>
            <Typography component="p">
              Because we value your privacy we have taken the necessary
              precautions to be in compliance with the California Online Privacy
              Protection Act. We therefore will not distribute your personal
              information to outside parties without your consent. As part of
              the California Online Privacy Protection Act, all users of our
              site may make any changes to their information at anytime by
              logging into their account page and editing their information.
            </Typography>
            <Box component="h3">
              Childrens Online Privacy Protection Act Compliance
            </Box>
            <Typography component="p">
              We are in compliance with the requirements of COPPA (Childrens
              Online Privacy Protection Act), we do not collect any information
              from anyone under 13 years of age. Our website, products and
              services are all directed to people who are at least 13 years old
              or older.
            </Typography>
            <Box component="h3">Online Privacy Policy Only</Box>
            <Typography component="p">
              This online privacy policy applies only to information collected
              through our website and not to information collected offline.{" "}
              <br /> Your Consent By using our site, you consent to our web site
              privacy policy.
            </Typography>
            <Box component="h3">Changes to Privacy Policy</Box>
            <Typography component="p">
              Any changes to the Privacy Policy will be posted on this page to
              keep you aware of what information is collected, how it is used,
              and under what circumstances it will be disclosed. It is your
              responsibility to review Millwoods' Privacy Policy each time you
              provide Personal Information to Millwoods as the policy may have
              changed since the last time you used the Site.
            </Typography>
          </Box>
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default PrivacyPolicy;

import {
  Box,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { PrimaryButton } from "src/components/Buttons";
import { contactInfos, inputs } from "./constants";
import TextArea from "src/components/TextArea";
import Banner from "src/components/Banner";
import { useState } from "react";
import GoogleMap from "src/components/GoogleMap";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import "./style.css";
import { connect } from "react-redux";
import { sendEnquiryMail } from "src/store";
import { emailValidator } from "src/helpers";
import ReCAPTCHA from "react-google-recaptcha";
import React, { useRef, RefObject } from "react";
import FloatingWhatsApp from "src/components/FloatingWhatsApp";

const Column = ({ children, sx }: { children: any; sx?: SxProps }) => (
  <Box sx={{ width: { md: "30%", xs: "100%" }, ...sx }}>{children}</Box>
);
const ContactInfo = ({
  sendEnquiryMail,
}: {
  sendEnquiryMail: (payload: any, callback: Function) => void;
}) => {
  const theme = useTheme();
  const [model, setModel] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const recaptchaRef: RefObject<any> = useRef();

  const onChange = (event: any, type?: string) => {
    const name = event?.target?.name;
    let value: string = event?.target?.value;
    setErrors({ ...errors, [name]: "" });
    if (type === "numberOnly") {
      value = value?.replace(/[^0-9]/g, "");
    }
    if (type === "charOnly") {
      value = value?.replace(/[^A-Z,a-z," "]/g, "");
    }
    let newParams: any = {};
    if (name == "countryCode") {
      newParams = { stateName: "" };
    }
    setModel({
      ...model,
      [name]: value,
      ...newParams,
    });
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    // Check if reCAPTCHA is verified
    if (!recaptchaRef.current.getValue()) {
      // reCAPTCHA not verified
      alert("Please verify that you are not a robot.");
      return;
    }

    if (!emailValidator(model?.email)) {
      setErrors({ email: "Please enter valid email ID" });
      return;
    }

    sendEnquiryMail(model, () => {
      const form: any = document?.getElementById("contact-form");
      let textArea: any = document?.getElementById("textarea-input");
      form?.reset();
      textArea.value = "";
      setModel({});
    });
  };
  return (
    <>
      <Banner
        url="/assets/img/contact-us/bg.webp"
        colorSx={{
          backgroundImage: "unset",
          backgroundColor: theme?.colors?.alpha?.black[5],
          mt: 0,
        }}
      >
        <Stack
          direction={{ md: "row", xs: "column-reverse" }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Column sx={{ width: { md: "30%", xs: "100%" } }}>
            {contactInfos?.map(({ title, info }, contactIndex) => (
              <Box key={contactIndex}>
                <SectionHeader sx={{ textAlign: "left" }}>
                  {title?.map((t, tIndex) =>
                    tIndex % 2 == 0 ? t : <HeaderSpan>{t}</HeaderSpan>
                  )}
                </SectionHeader>
                <Box component="ul" sx={{ listStyle: "none", paddingLeft: 0 }}>
                  {info?.map((item, index) => {
                    let props = item?.value ? { href: item?.value } : {};
                    return (
                      <Box
                        key={index}
                        component="li"
                        sx={{
                          mb: 1,
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          {item?.icon}
                          <Box
                            component={
                              item?.value && !item?.address ? "a" : "span"
                            }
                            target="_blank"
                            sx={{
                              color: theme?.colors?.alpha?.secondary[100],
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                            {...props}
                          >
                            {item?.label}
                          </Box>
                        </Box>
                        {item?.address && (
                          <GoogleMap
                            sx={{
                              ml: 0,
                              mt: 1,
                              height: { md: 250, xs: 250 },
                              width: "100%",
                              borderRadius: 0.5,
                            }}
                            src={item?.value}
                          />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Column>
          <Column
            sx={{ width: { md: "65%", xs: "100%" }, mb: { md: 0, xs: 3 } }}
          >
            <SectionHeader sx={{ textAlign: "left" }}>
              For <HeaderSpan>Enquiry</HeaderSpan>
              <Typography component="p">
                let us know how we can help you
              </Typography>
            </SectionHeader>
            <Box component="form" id="contact-form" onSubmit={handleSubmit}>
              <Box
                sx={{
                  borderColor: "white",
                  borderRadius: 0.5,
                  backgroundColor: theme?.colors?.alpha.white[100],
                  p: 5,
                  py: { md: 8 },
                  ".MuiOutlinedInput-notchedOutline": {
                    borderRadius: 0,
                  },
                }}
              >
                {inputs?.map((item, index) => {
                  if (item?.type === "textarea") {
                    return (
                      <TextArea
                        {...item}
                        id="textarea-input"
                        multiline
                        rows={2}
                        key={index}
                        name={item?.key}
                        type={item?.type || "text"}
                        sx={{
                          mt: 2.5,
                          width: "100%",
                        }}
                        label=""
                        placeholder={item?.label}
                        value={model[item?.key]}
                        onChange={(event) => onChange(event, item?.type)}
                        inputProps={{
                          ...item,
                          style: {
                            height: 200,
                          },
                        }}
                      />
                    );
                  }
                  return (
                    <>
                      <TextField
                        {...item}
                        key={index}
                        name={item?.key}
                        type={item?.type || "text"}
                        sx={{
                          mt: 2.5,

                          width: "100%",
                        }}
                        label=""
                        placeholder={item?.label}
                        value={model[item?.key]}
                        onChange={(event) => onChange(event, item?.type)}
                        inputProps={{
                          ...item,
                          maxLength: item?.maxLength,
                          minLength: item?.minLength,
                          style: { height: 10 },
                        }}
                      />
                      <Typography color="red" component="span">
                        {errors[item?.key]}
                      </Typography>
                    </>
                  );
                })}
                <Box sx={{ mt: 3 }}>
                  <ReCAPTCHA
                    key="recaptcha"
                    ref={recaptchaRef}
                    sitekey="6Lf7hVIpAAAAANiiL8M0ZM0hb5ABgGdFoebf1GQa"
                    onChange={(value) => console.log("reCAPTCHA value:", value)}
                  />
                </Box>
              </Box>

              <PrimaryButton
                type="submit"
                sx={{ mt: 2.5, borderRadius: 0.5, width: "100%" }}
              >
                Submit
              </PrimaryButton>
            </Box>
          </Column>
        </Stack>
      </Banner>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendEnquiryMail: (payload: any, callback: Function) =>
    dispatch(sendEnquiryMail(payload, callback)),
});
export default connect(null, mapDispatchToProps)(ContactInfo);

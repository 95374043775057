import { Container, Stack, useTheme } from "@mui/material";
import SectionHeader, {
  HeaderSpan,
  SectionHeaderTop,
} from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import { faqs as defaultFaqs } from "./constants";
import Card from "./card";

const Faqs = ({ faqs = defaultFaqs }: { faqs?: any[] }) => {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ mt: "4rem", mb: { md: "5rem" } }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <SectionHeaderTop
          sx={{
            textAlign: "center",
            color: theme?.colors?.alpha?.black[100],
          }}
        >
          Few <HeaderSpan>FAQs</HeaderSpan>
        </SectionHeaderTop>
        <SectionHeader
          sx={{
            textAlign: "center",
            color: theme?.colors?.alpha?.black[100],
            textTransform: "none",
          }}
        >
          Frequently Asked <HeaderSpan>Questions</HeaderSpan>
        </SectionHeader>
        <Stack
          direction={{ md: "row" }}
          justifyContent={{ md: "space-between", xs: "center" }}
          alignItems="center"
          flexWrap={"wrap"}
          sx={{
            mt: { md: 2.5, xs: 2 },
            gap: { md: 3, xs: 0 },
          }}
        >
          {faqs?.map((item: any, index) => {
            return (
              <Card
                key={index}
                item={{
                  ...item,
                }}
                index={index}
              />
            );
          })}
        </Stack>
      </Container>
    </SectionContainer>
  );
};

export default Faqs;

import { call, delay, put, takeLatest } from "redux-saga/effects";
import {} from "../../interfaces";
import { FETCH_CITY_AIRPORT_DICTIONARIES, SEARCH_CITIES } from "./types";
import { fetchAirportCityDetail, fetchAirportsCities } from "../../services";
import {
  setCityAirportDictionaries,
  setSearchCities,
  setSearchCitiesLoader,
} from "./actions";
import { removeLoader } from "../loader";
import { handleFetchAmadeusToken, store } from "src/store";

let completed = true;
function* handleSearchCities({ payload }: { payload: any }): Generator<any> {
  const token = yield call(handleFetchAmadeusToken);
  yield put(setSearchCitiesLoader(true));
  try {
    if (!completed) {
      yield delay(100);
    }
    completed = false;
    const responseData: any = yield call(
      fetchAirportsCities as any,
      payload?.keyword,
      token
    );
    const cities = responseData?.data?.map((item) => {
      return {
        label: `${item?.address?.cityName}, ${item?.address?.countryName} - ${item?.iataCode}`,
        value: item?.address?.cityCode,
      };
    });
    yield put(
      setSearchCities({ key: payload?.key, cities: cities, loading: false })
    );
    payload?.callback && payload?.callback(cities);
    completed = true;
  } catch (error) {
    completed = true;
  }
}

let cityAirportDictionaries = {};
function* handleCityAirportDictionary(code: string): Generator<any> {
  const token = yield call(handleFetchAmadeusToken);
  const responseData: any = yield call(
    fetchAirportCityDetail as any,
    code,
    token
  );
  const finalData = {
    [code]: responseData,
  };
  const previous = store?.getState()?.locations?.cityAirportDictionaries || {};
  cityAirportDictionaries = {
    ...previous,
    ...cityAirportDictionaries,
    ...finalData,
  };

  yield put(setCityAirportDictionaries(cityAirportDictionaries));
}

function* handleCityAirportDictionaries({
  payload,
}: {
  payload: any;
}): Generator<any> {
  try {
    const previous =
      store?.getState()?.locations?.cityAirportDictionaries || {};
    for (let i = 0; i < payload?.codes?.length; i++) {
      const key = payload?.type + payload?.codes[i];
      if (!Object?.keys(previous || {})?.includes(key)) {
        yield call(handleCityAirportDictionary, key);
      }
    }
  } catch (error) {}
  yield put(removeLoader());
}

export function* locationSaga() {
  yield takeLatest<any>(SEARCH_CITIES, handleSearchCities);
  yield takeLatest<any>(
    FETCH_CITY_AIRPORT_DICTIONARIES,
    handleCityAirportDictionaries
  );
}

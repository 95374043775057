import { Box, List, ListItem, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { menus } from "../../constants";

const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(["color", "fill"])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                            transition: all .2s;
                            // background: ${theme.colors.primary.main};
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: ${theme?.colors?.primary?.main};
                    transition: all .2s;
                    .MuiTypography-root{
                      color:white !important;
                    }
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu({ color }: { color?: string }) {
  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
        }}
      >
        <List disablePadding component={Box} display="flex">
          {menus.map((item, index) => (
            <ListItem
              key={index}
              classes={{ root: "MuiListItem-indicators" }}
              component={NavLink}
              to={item?.path}
              sx={{ py: "0.2rem !important", mr: 1 }}
            >
              <ListItemText
                primaryTypographyProps={{
                  noWrap: true,
                  sx: { color: color || "white" },
                  fontWeight: "bold",
                }}
                primary={item?.title}
              />
            </ListItem>
          ))}
        </List>
      </ListWrapper>
    </>
  );
}

export default HeaderMenu;

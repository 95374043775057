import {
  Box,
  Slider as DefaultSlider,
  SliderProps as DefaultSliderProps,
  useTheme,
} from "@mui/material";

interface SliderProps extends DefaultSliderProps {
  label?: any;
}
const Slider = (props: SliderProps) => {
  const theme = useTheme();
  function valuetext(value: number) {
    return `${value}°C`;
  }
  const isMobile = document?.body?.clientWidth <= 991;
  return (
    <Box sx={{ position: "relative", width: "100%", mt: { md: 1, xs: 2 } }}>
      {props?.label}
      <DefaultSlider
        size="small"
        color="primary"
        getAriaLabel={valuetext}
        components={{
          Track: () => (
            <Box
              sx={{
                position: "absolute",
                width: "100%",
                backgroundColor: theme?.colors?.secondary?.light,
                height: 4,
                mt: -0.1,
              }}
            />
          ),
        }}
        valueLabelDisplay={isMobile ? "on" : "auto"}
        {...props}
      />
    </Box>
  );
};

export default Slider;

import dayjs from "dayjs";

export const inputs = (model: any) => [
  {
    key: "origin",
    label: "Origin",
    type: "autocomplete",
    required: true,
  },
  {
    key: "destination",
    label: "Destination",
    type: "autocomplete",
    required: true,
  },
  {
    key: "departureDate",
    label: "Date",
    type: "date",
    minWidth: "30%",
    required: true,
    minDate: dayjs(new Date()),
  },
  {
    key: "returnDate",
    label: "Return Date",
    type: "date",
    minWidth: "30%",
    required: true,
    minDate: dayjs(new Date()),
    showOn: model?.type === "round",
  },
];

export const passengersDetail = [
  { title: "Adults", subTitle: "12+ years", key: "adults" },
  { title: "Children", subTitle: "2 - 12 years", key: "children" },
  { title: "Infants", subTitle: "0 - 2 years", key: "infants" },
];

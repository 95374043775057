import { Box, Container } from "@mui/material";
import Breadcrumbs from "src/components/Breadcrumb";
import SectionContainer from "src/components/SectionContainer";

const BookingRules = () => {
  return (
    <SectionContainer>
      {/* <Breadcrumbs title={["Rules &", "Restrictions"]} /> */}
      <Breadcrumbs title={["","Terms"]}  />
      <Container maxWidth="lg" sx={{ mt: { md: 5, xs: 6 }, mb: 3 }}>
        <Box sx={{}}>
          <Box component="ul" sx={{ pl: 1, lineHeight: 2.8 }}>
            <Box component="li">
              Review your trip details to make sure the dates and times are
              correct.
            </Box>
            <Box component="li">
              Check your spelling. Flight passenger names must match
              government-issued photo ID exactly.
            </Box>
            <Box component="li">
              Name changes are not permitted once tickets are issued. Tickets
              are non-transferable and non-refundable.
            </Box>
            <Box component="li">
              Millwoods does not assume any liability thereafter for any
              discrepancy in your booking.
            </Box>
            <Box component="li">
              Total fares include all taxes and fees except additional airline
              fees such as baggage, seat assignment etc. that may apply.
            </Box>
            <Box component="li">
              Your credit card may be billed in multiple charges totaling the
              above amount. The charges may show as ARC, Millwoods or Agent fee.
            </Box>
            <Box component="li">
              Changes are permitted as per airline's rules, restrictions and
              penalties are applicable to these fares however change fees and
              fare differences may apply.
            </Box>
            <Box component="li">
              Fares are not guaranteed until ticketed. E-ticket will be sent to
              your registered email address within 3-24 hrs after the payment
              has been received.
            </Box>
            <Box component="li">
              All travelers are responsible for obtaining the necessary visas
              and/or travel documents for all countries they are visiting or
              transiting through.
            </Box>
            <Box component="li">
              Reliable information regarding international travel can be found
              at <a href="http://www.travel.state.gov/">www.travel.state.gov</a>{" "}
              and also with the consulate/embassy of the country you are
              visiting or transiting through. Millwoods will not be responsible
              if proper travel documents are not available and you are denied
              entry or transit into a Country.
            </Box>
            <Box component="li">
              If your itinerary includes two separate tickets operated by more
              than one airline, please read carefully each such airline's terms
              and conditions, which can be found on each such airline's website.
              Each such airline will have its own restrictions, rules, and fees.
              If one of these flights is affected by an airline change (e.g.
              cancellation or rescheduling) that causes a Customer to make
              changes to the other flight, the Customer may be responsible for
              any fees or ticket cost incurred for making changes to the
              unaffected flight. Such airlines may charge their own fees for
              changes, refunds, or if exchanges are requested. You are
              responsible for complying with each airline's terms and
              conditions, which may differ (for example, check-in times and
              limits on baggage size/weight).
            </Box>
            <Box component="li">
              All flights should be confirmed with the airline directly as they
              may have last minute schedule changes. You must reconfirm at least
              24 hours prior to departure for domestic flights and 72 hours for
              flights to international destinations.
            </Box>
            <Box component="li">
              Please note that your seats, meals, frequent flyer and other
              special requests are "requests only" and cannot be guaranteed by
              Millwoods. All requests will be forwarded to the airlines. Please
              contact the airline directly if you want to receive immediate
              confirmation on your seat assignment or if you have any special
              requirement such as stretcher or wheelchair assistance.
            </Box>
            <Box component="li">
              If your credit card declines at the time of the processing your
              transaction, we will make all efforts to notify you by email
              within 24 hours. The transaction will not be processed if your
              credit card has been declined. The fare and any other booking
              details are not guaranteed in such instance.
            </Box>
            <Box component="li">
              Millwoods will not be responsible for providing any compensation
              in case of airline schedule change.We make every attempt to notify
              the customer of any schedule changes. It is always best to contact
              the airline to reconfirm your flights within 48 hours of
              departure.
            </Box>
            <Box component="li">
              For date change and cancellation, please contact us 72 hours
              before departure. Any and all changes/cancellations made to the
              itinerary are restricted and are subject to the airline fare
              rules, penalties and other applicable charges whichever is more
              restrictive; most of our tickets do not allow any name changes or
              rerouting after the booking is completed. Date change is strictly
              subject to the availability.
            </Box>
            <Box component="li">
              Recommended arrival time for Domestic Travel - The recommended
              arrival time at the Airline counter is 2 hours prior to departure
              when traveling within the Canada or India. You must be checked in
              at least 45 minutes before your scheduled departure time.
            </Box>
            <Box component="li">
              Recommended arrival time for International Travel - The
              recommended arrival time at the Airline counter prior to departure
              of international flights is 3 hours. You must be checked in at
              least 60 minutes before your scheduled departure time. This
              minimum requirement applies to all customers checking-in, with or
              without baggage.
            </Box>
            <Box component="li">
              Tickets are non-refundable after departure.
            </Box>
            <Box component="li">
              Ticket has no value if no show before departure.
            </Box>
          </Box>
        </Box>
      </Container>
    </SectionContainer>
  );
};

export default BookingRules;

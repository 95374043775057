import { Box, Link, Stack, useTheme } from "@mui/material";
import { socialMedia } from "./constants";

function SocialMedia() {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      spacing={1}
      component="ul"
      sx={{ listStyle: "none", paddingLeft: 0 }}
    >
      {socialMedia?.map((item, index) => {
        return (
          <Box
            key={index}
            component="li"
            sx={{
              display: "flex",
              mb: 1,
            }}
          >
            <Link
              sx={{
                color: theme?.colors?.secondary?.main,
                textDecoration: "none",
                
              }}
              target= "_blank" 
              href={item?.value}
            >
              {item?.icon}
            </Link>
          </Box>
        );
      })}
    </Stack>
  );
}

export default SocialMedia;

import { Box } from "@mui/material";
import Breadcrumbs from "src/components/Breadcrumb";
import ContactInfo from "./contact";

const ContactUs = () => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs title={["Contact", "Us"]} />
        <ContactInfo />
      </Box>
    </>
  );
};

export default ContactUs;

import {
  TextField as DefaultTextField,
  TextFieldProps,
  styled,
} from "@mui/material";

const TextField = styled(DefaultTextField)(`
        .MuiInputBase-formControl{
            padding:0 !important;
        }
        .MuiInputBase-formControl>textarea{
            padding:5.5px 14px !important;
        }
`);
const TextArea = (props: TextFieldProps) => {
  return <TextField {...props} />;
};

export default TextArea;

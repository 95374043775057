import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import Card from "../../../components/Card";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import PaperIcon from "src/components/PaperIcon";

const WhatWeDo = () => {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ mt: "4rem", mb: { md: "5rem" } }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Box width={{ md: "60%" }}>
            <SectionHeader sx={{ textAlign: "start" }}>
              We don't just <HeaderSpan>BOOK FLIGHTS</HeaderSpan>
              <Box
                sx={{
                  fontSize: 16,
                  fontWeight: "600",
                  color: theme?.colors?.alpha?.black[70],
                }}
              >
                We craft unforgettable adventures
              </Box>
            </SectionHeader>
            <Box sx={{ width: "100%" }}>
              <Typography component="p" sx={{ textAlign: "justify", mb: 1 }}>
                Welcome to Millwoods, the best travel agency in Calgary and
                Edmonton, your one-stop destination for seamless and memorable
                travel experiences. At Millwoods, we go beyond being just a
                flight booking company; we are your travel companion, dedicated
                to turning your journeys into exciting adventures. Founded with
                a passion for easing travel, we take pride in offering a
                platform that meets all your flight booking needs. Our journey
                began with a vision to redefine the way people experience and
                plan their travels. With a team of dedicated professionals, each
                sharing a deep love for exploration, Millwoods has evolved into
                a trusted name in the travel industry.
              </Typography>
              <Typography component="p" sx={{ textAlign: "justify", mb: 1 }}>
                At the heart of Millwoods is a commitment to affordability
                without compromising on quality. We strongly believe that
                everyone should have easy access to the wonders of the world,
                and our platform is designed to bring you the best deals and
                offers available. Whether you're a budget traveller or seeking
                luxury, we ensure that your travel goals align with your
                financial plans.
              </Typography>
              <Typography component="p" sx={{ textAlign: "justify", mb: 1 }}>
                Our wide network of partnerships with multiple airlines allows
                us to offer a diverse range of routes, ensuring that you can
                reach your dream destination conveniently. What truly sets us
                apart is our passion for around-the-clock customer satisfaction.
                Our dedicated customer support team works hard enough so that
                you don’t have to. They will assist you at every stage of your
                journey ensuring a hassle-free booking experience. From helping
                you find the best deals to addressing any concerns, we are here
                to ensure your travel experience is nothing short of
                exceptional. We understand that a seamless journey is more than
                just booking cheap flights; our goal is to be your one-stop
                solution for all your travel needs.
              </Typography>
            </Box>
          </Box>
          <Card
            icon={"/assets/img/about-us/about-flight.webp"}
            alt="cheap flight tickets"
            imgSx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            sx={{
              mb: {
                md: 0,
                xs: "2rem !important",
              },
              boxShadow: "unset",
              width: { md: "35%" },
              mt: { md: 5 },
              padding: 0,
            }}
          />
        </Stack>
      </Container>
      <PaperIcon src="/assets/icons/paper-icon-2.svg" sx={{ left: 0 }} />
    </SectionContainer>
  );
};

export default WhatWeDo;

import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";

export const socialMedia = [
  {
    icon: <FacebookIcon />,
    value: "https://www.facebook.com/mymillwoodstravels/",
  },
  {
    icon: <InstagramIcon />,
    value: "https://www.instagram.com/millwoodstravel/",
  },
];
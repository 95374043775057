import { Box, BoxProps, useTheme } from "@mui/material";

const SectionHeader = (props: BoxProps) => {
  return (
    <Box
      component="h1"
      {...props}
      sx={{
        fontSize: 30,
        marginBottom: 3,
        color: "black",
        textAlign: "center",
        width: "100%",
        textTransform: "capitalize",
        fontFamily: `'Roboto', sans-serif`,
        // lineHeight: 1.2,
        fontWeight: "500",
        mt: 0,
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

export default SectionHeader;

export const SectionHeaderTop = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        fontSize: 18,
        color: "black",
        width: "100%",
        textAlign: "center",
        textTransform: "capitalize",
        fontFamily: `'Kalam', cursive !important`,
        span: {
          fontFamily: `'Kalam', cursive !important`,
        },
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

export const HeaderSpan = (props: BoxProps) => {
  const theme = useTheme();
  return (
    <Box
      {...props}
      component="span"
      sx={{
        color: theme?.colors?.primary.main,
        fontWeight: "bold",
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

import {
  Box,
  Rating,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Card from "../../../components/Card";
import SectionHeader, {
  HeaderSpan,
  SectionHeaderTop,
} from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import Banner from "src/components/Banner";
import { reducerProps } from "src/interfaces";
import { fetchGoogleReview } from "src/store";
import { connect } from "react-redux";
import { useEffect } from "react";
import { reviewsRoot } from "src/interfaces";
import StarIcon from "@mui/icons-material/Star";

const GoogleReviews = ({
  banner,
  fetchGoogleReview,
  googleReviews,
}: {
  banner?: string;
  fetchGoogleReview: Function;
  googleReviews?: reviewsRoot;
}) => {
  const theme = useTheme();
  const reviews = googleReviews?.result?.reviews;
  useEffect(() => {
    fetchGoogleReview();
  }, []);
  const textColor = banner && "white";
  return (
    <SectionContainer
      sx={{
        mt: { md: banner ? "-4.5rem" : "-1.7rem", xs: "3.5rem" },
        mb: { md: "5rem", xs: "3.5rem" },
      }}
    >
      <Banner
        url={banner || "/assets/img/reviews-bg.webp"}
        colorSx={{ backgroundImage: "unset" }}
      >
        <SectionHeaderTop sx={{ textAlign: "center", color: textColor }}>
          Happy <HeaderSpan>Customers</HeaderSpan>
        </SectionHeaderTop>
        <SectionHeader sx={{ textAlign: "center", color: textColor, mb: 0.5 }}>
          Our <HeaderSpan>Reviews</HeaderSpan>
        </SectionHeader>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mb={2}
          spacing={1}
        >
          <Box
            component="img"
            src="/assets/icons/google-logo.svg"
            sx={{ width: 17, objectFit: "contain" }}
          />
          <Typography sx={{ fontSize: 18 }} color={textColor}>
            {googleReviews?.result?.rating}
          </Typography>
          <StarIcon
            color="primary"
            fontSize="medium"
            sx={{ top: -1.5, position: "relative" }}
          />
          <Typography
            component="a"
            color={textColor}
            href={googleReviews?.result?.url}
            sx={{ textDecoration: "none" }}
            target="_blank"
          >
            ({googleReviews?.result?.user_ratings_total} reviews)
          </Typography>
        </Stack>
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          {reviews
            ?.filter((g) => g.rating >= 4)
            ?.slice(0, 4)
            ?.map((item, index: number) => (
              <Card
                key={index}
                imgSx={{
                  display: "none",
                }}
                sx={{
                  mb: {
                    md: 0,
                    xs: "2rem !important",
                  },
                  boxShadow: "unset",
                  width: { md: "30%" },
                  height: { md: 250, xs: 300 },
                  padding: { xs: 1.8, md: 1.6 },
                  backgroundColor: theme?.colors?.alpha?.white[30],
                }}
                title={item?.author_name}
                titleSx={{
                  textAlign: "start",
                  textTransform: "capitalize",
                  color: textColor,
                }}
                description={
                  <Box
                    sx={{
                      color: textColor,
                    }}
                  >
                    <Rating
                      value={item?.rating}
                      readOnly
                      sx={{ color: theme?.colors?.primary?.main }}
                    />
                    <Tooltip
                      sx={{ display: { md: "block", xs: "none" } }}
                      title={item?.text}
                    >
                      <Typography component="p" title={item?.text}>
                        {`${item?.text.substring(0, 308)}${
                          item?.text.length > 308 ? "..." : ""
                        }`}
                      </Typography>
                    </Tooltip>
                    <Typography
                      sx={{ display: { xs: "block", md: "none" } }}
                      component="p"
                      title={item?.text}
                    >
                      {`${item?.text.substring(0, 475)}${
                        item?.text.length > 475 ? "..." : ""
                      }`}
                    </Typography>
                  </Box>
                }
                descriptionSx={{
                  textAlign: "start",
                }}
              />
            ))}
        </Stack>
      </Banner>
    </SectionContainer>
  );
};

const mapStateToProps = ({ googleReviews }: reducerProps) => ({
  googleReviews,
});
const mapDispatchToProps = (dispatch) => ({
  fetchGoogleReview: () => dispatch(fetchGoogleReview()),
});
export default connect(mapStateToProps, mapDispatchToProps)(GoogleReviews);

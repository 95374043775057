import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import Card from "../../../components/Card";
import { PrimaryButton } from "src/components/Buttons";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import Banner from "src/components/Banner";

const FlightSchedule = () => {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ mt: "4rem", mb: 0 }}>
      <Banner
        url="/assets/img/about-us/schedule-bg.svg"
        colorSx={{ backgroundImage: "unset" }}
      >
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Card
            icon={"/assets/img/about-us/schedule.webp"}
            alt="best travel company"
            imgSx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            sx={{
              mb: {
                md: 0,
                xs: "2rem !important",
              },
              position: "relative",
              boxShadow: "unset",
              width: { md: "30%" },
              padding: 0,
              backgroundColor: "transparent",
            }}
          />
          <Box width={{ md: "60%" }}>
            <SectionHeader
              sx={{
                textAlign: "start",
                color: theme?.colors?.alpha?.white[100],
                mb: 0,
                lineHeight: 1.5,
                fontSize: 20,
              }}
            >
              Check
            </SectionHeader>
            <SectionHeader
              sx={{
                textAlign: "start",
                color: theme?.colors?.alpha?.white[100],
                mb: 0,
                lineHeight: 1.5,
                fontSize: 20,
              }}
            >
              <HeaderSpan>FLIGHT SCHEDULE</HeaderSpan> and
            </SectionHeader>
            <SectionHeader
              sx={{
                textAlign: "start",
                color: theme?.colors?.alpha?.white[100],
                lineHeight: 1,
              }}
            >
              <HeaderSpan>BOOK CHEAP FLIGHTS</HeaderSpan>
            </SectionHeader>
            <Box
              sx={{ width: "100%", color: theme?.colors?.alpha?.white[100] }}
            >
              <Typography component="p" sx={{ textAlign: "justify", mb: 1 }}>
                Navigating through the complexities of travel can be daunting,
                but at Millwoods, we believe it should be as effortless as the
                destinations you dream of. Our robust online interface ensures a
                seamless flight searching process, allowing you to smoothly
                check flight schedules and book your flights with just a few
                clicks. Our website offers numerous flight options and provides
                valuable customer guidance into various destinations, ensuring
                that you make informed travel decisions.
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Banner>
    </SectionContainer>
  );
};

export default FlightSchedule;

import MailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AddressIcon from "@mui/icons-material/Map";

export const contactInfo1 = [
  {
    icon: <MailIcon sx={{ color: "#ffac0a", mr: 1 }} />,
    label: "millwoodstravelsyyc@gmail.com",
    value: "mailto:millwoodstravelsyyc@gmail.com",
  },
  {
    icon: <PhoneIcon sx={{ color: "#ffac0a", mr: 1 }} />,
    label: "+1 403-200-0222",
    value: "tel:+1 403-200-0222",
  },
  {
    icon: <AddressIcon sx={{ color: "#ffac0a", mr: 1 }} />,
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2503.679154101842!2d-113.95190592411632!3d51.13282593799893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x537163bf01ab7321%3A0xf5edc59ff7e1900f!2s5850%2088%20Ave%20NE%206120%20ab%2C%20Calgary%2C%20AB%20T3J%200Z7%2C%20Canada!5e0!3m2!1sen!2sin!4v1695812316766!5m2!1sen!2sin",
    address: true,
    label: "5850 88 Ave NE, #6120,Calgary, AB T3J 0Z7",
  },
];

export const contactInfo2 = [
  {
    icon: <MailIcon sx={{ color: "#ffac0a", mr: 1 }} />,
    label: "millwoodstravelsyyc@gmail.com",
    value: "mailto:millwoodstravelsyyc@gmail.com",
  },
  {
    icon: <PhoneIcon sx={{ color: "#ffac0a", mr: 1 }} />,
    label: "+1 780-244-3222",
    value: "tel:+1 780-244-3222",
  },
  {
    icon: <AddressIcon sx={{ color: "#ffac0a", mr: 1 }} />,
    value:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2375.6215625351874!2d-113.36957882292671!3d53.45735086641155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a019905de3a0bf%3A0x7c7e678efb2566dd!2s2543%2017%20St%20NW%2C%20Edmonton%2C%20AB%20T6T%200Y2%2C%20Canada!5e0!3m2!1sen!2sin!4v1729521696740!5m2!1sen!2sin",
    address: true,
    label: "2543 17th St NW, Edmonton AB T6T 0Y2 ",
  },
];

export const inputs = [
  {
    key: "fullName",
    label: "Name",
    type: "text",
    required: true,
  },
  {
    key: "phoneNumber",
    label: "Contact No.",
    type: "numberOnly",
    required: true,
    minLength: 10,
    maxLength: 10,
  },
  {
    key: "email",
    label: "Email Address ",
    type: "email",
    required: true,
  },
];

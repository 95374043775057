import { Box, Container, Stack, Typography, useTheme } from "@mui/material";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";
import BlogCard from "./Card";
import { useEffect, useState } from "react";

const Blogs = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    fetch('/blogs.json').then(res => {
      if (res.status === 200) {
        res.json().then(responseData => {
          setData(responseData)
        })
      }
    })
  }, [])
  const theme = useTheme();
  return (
    <SectionContainer sx={{ mt: "4rem", mb: { md: "5rem" } }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <SectionHeader sx={{ textAlign: "center" }}>
          Transforming Dreams <HeaderSpan>into Unforgettable Journeys</HeaderSpan>
        </SectionHeader>
        <Box sx={{ width: "100%" }}>
          <Typography component="p" sx={{ textAlign: "center" }}>
            Welcome to Millwoods Travels, as your ultimate travel companion, we take pride in being your go-to destination for all your travel needs. With years of experience and a team of dedicated travel agents in Calgary and Edmonton, we craft journeys that reflect unbeatable value. Let us turn your travel aspirations into reality, offering not just trips, but transformative experiences coupled with the best deals.
          </Typography>
        </Box>
        <Stack
          direction={{ md: "row" }}
          justifyContent={{ md: "space-between", xs: "center" }}
          alignItems="center"
          flexWrap={"wrap"}
          gap={{ md: 3, xs: 0 }}
          sx={{
            mt: { md: 2.5, xs: 2 },
          }}
        >
          {data?.map((item: any, index) => {
            return (
              <BlogCard
                key={index}
                item={{
                  ...item
                }}
                index={index}
              />
            );
          })}
        </Stack>
      </Container>
    </SectionContainer>
  );
};

export default Blogs;

import { Box, Container, Stack, Typography } from "@mui/material";
import { cards, payload } from "./constants";
import DealCard from "./DealCard";
import SectionHeader, {
  HeaderSpan,
  SectionHeaderTop,
} from "../../../components/SectionHeader";
import SectionContainer from "../../../components/SectionContainer";
import { connect } from "react-redux";
import {
  fetchDealFlights,
  fetchFlights,
  setCurrentRoute,
  setSearchModel,
} from "src/store";
import { flightSearchProps, reducerProps, routeProps } from "src/interfaces";
// import { useEffect } from "react";
import PaperIcon from "../../../components/PaperIcon";

const FlightDeals = ({
  deals = {},
  auth,
  fetchDealFlights,
  fetchFlightOffers,
  model,
  setModel,
  setRoute,
}: {
  auth: any;
  deals: any;
  fetchDealFlights: (payload: any) => void;
  fetchFlightOffers: (payload: any) => void;
  model: flightSearchProps;
  setModel: (payload: flightSearchProps) => void;
  setRoute: (payload: routeProps) => void;
}) => {
  // useEffect(() => {
  //   fetchDealFlights(payload);
  // }, [auth]);
  return (
    <SectionContainer>
      <Container maxWidth="lg" sx={{}}>
        <SectionHeaderTop>
          Top <HeaderSpan>Destinations</HeaderSpan>
        </SectionHeaderTop>
        <SectionHeader sx={{ mb: 0.5 }}>
          Popular <HeaderSpan sx={{}}>Routes</HeaderSpan>
        </SectionHeader>
        <Box sx={{ width: "100%", textAlign: "center", my: 3 }}>
          <Typography sx={{ px: { md: "25%", xs: "0%" } }} component="p">
            Discover top destinations with cheap flights. Easily check flight
            schedules by providing departure, arrival, and travel dates. Your
            adventure begins here.
          </Typography>
        </Box>
        <Stack
          direction="row"
          sx={{
            display: { md: "flex", xs: "block" },
            gap: { md: 3, xs: 0 },
          }}
        >
          {cards?.map((item: any, index) => {
            return (
              <DealCard
                key={index}
                onClick={() => {
                  setModel({
                    ...model,
                    ...payload?.originDestinations[index],
                    departureDate: payload?.departureDate,
                    adults: payload?.adults,
                    type: payload?.type,
                    travelClass: payload?.travelClass,
                  });
                  const finalPayload = {
                    ...payload,
                    ...payload?.originDestinations[index],
                  };
                  fetchFlightOffers(finalPayload);
                  let urlPayload = "";
                  Object?.keys(finalPayload)
                    ?.filter((ele) => ele != "originDestinations")
                    ?.map((key) => {
                      if (finalPayload[key]) {
                        urlPayload += urlPayload
                          ? `&${key}=${JSON?.stringify(finalPayload[key])}`
                          : `?${key}=${JSON?.stringify(finalPayload[key])}`;
                      }
                    });
                  setRoute({ path: "flights" + urlPayload });
                }}
                item={{
                  ...item,
                  right: {
                    ...item?.right,
                    price: deals[item?.right?.price],
                  },
                }}
                index={index}
              />
            );
          })}
        </Stack>
      </Container>
      <PaperIcon
        src="/assets/icons/bg-why-unique.svg"
        sx={{ top: -80, zIndex: -1 }}
      />
    </SectionContainer>
  );
};

const mapStateToProps = ({
  flights: { deals },
  searchModel,
  auth,
}: reducerProps) => ({
  model: searchModel,
  deals,
  auth,
});
const mapDispatchToProps = (dispatch) => ({
  setModel: (payload: any) => dispatch(setSearchModel(payload)),
  setRoute: (payload: any) => dispatch(setCurrentRoute(payload)),
  fetchDealFlights: (payload: any) => dispatch(fetchDealFlights(payload)),
  fetchFlightOffers: (payload: any) => dispatch(fetchFlights(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(FlightDeals);

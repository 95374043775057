import { call, put, takeLatest } from "redux-saga/effects";
import {} from "../../interfaces";
import { FETCH_GOOGLE_REVIEWS, SEND_ENQUIRY_MAIL } from "./types";
import { googleReviews, sendMail } from "../../services";
import { setGoogleReview } from "./actions";
import { reviews } from "src/mockData/reviews";
import { setAlert } from "../alert";
import { removeLoader, setLoader } from "../loader";

function* handleGoogleReviews(): Generator<any> {
  try {
    // const responseData: any = yield call(googleReviews);
    yield put(setGoogleReview(reviews));
  } catch (error) {}
}

function* handleSendMail({
  payload,
  callback,
}: {
  payload: any;
  callback: Function;
}): Generator<any> {
  yield put(setLoader({ transparent: true, visible: true }));
  try {
    const mailBody = `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
      
    </head>
    
    <body>
     <div class="table-item" style=" padding: 16px 19px; margin: auto; width: 460px; border-radius: 5px;border: 1px solid #bababa;margin-top: 40px;">
        <table style="width: 100%;">
            <tbody style="font-family: 'Inter', sans-serif;">
           <tr>
            <th colspan="2" style="font-size:22px;padding-bottom: 20px;">Contact Details</th>
           </tr>
                <tr>
                    <td style="font-size: 17px;width: 100px;padding: 10px 8px;border-bottom: 1px solid #bababa;"><b>Name:</b></td>
                    <td style="font-size: 18px;border-bottom: 1px solid #bababa;">${payload?.name}</td>
                </tr>
                <tr>
                    <td style="font-size: 17px;width: 100px;padding: 10px 8px;border-bottom: 1px solid #bababa;"><b>Email:</b></td>
                    <td style="font-size: 18px;border-bottom: 1px solid #bababa;">${payload?.email}</td>
                </tr>
                <tr>
                    <td style="font-size: 17px;width: 100px;padding: 10px 8px;border-bottom: 1px solid #bababa;"><b>Phone No:</b></td>
                    <td style="font-size: 18px;border-bottom: 1px solid #bababa;">${payload?.phone}</td>
                </tr>
           <tr>
                    <td style="font-size: 17px;width: 100px;padding: 10px 8px;"><b>Message:</b></td>
                    <td style="font-size: 18px;">${payload?.message}</td>
                </tr>
            </tbody>
        </table>                            
    </div>
    </body>
    </html>`;

    const subject = "New Enquiry Mail";
    yield call(sendMail, mailBody, subject, payload?.email);
    yield put(setAlert({ text: "Mail sent successfully", type: "success" }));
    setTimeout(() => {
      callback && callback();
    }, 100);
  } catch (error) {
    yield put(
      setAlert({ text: error?.message || "Server error", type: "error" })
    );
  }
  yield put(removeLoader());
}

export function* additionalSaga() {
  yield takeLatest<any>(FETCH_GOOGLE_REVIEWS, handleGoogleReviews);
  yield takeLatest<any>(SEND_ENQUIRY_MAIL, handleSendMail);
}

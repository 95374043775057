import { Box } from "@mui/material";
import WhatWeDo from "./WhatWeDo";
import Counters from "../components/Counters";
import Breadcrumbs from "src/components/Breadcrumb";
import DedicatedAgent from "./DedicatedAgent";
import GoogleReviews from "../components/googleReviews";
import FlightSchedule from "./FlightSchedule";
import Subscribe from "../components/Subscribe";

const Home = () => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs title={["About", "Us"]} />
        <WhatWeDo />
        <FlightSchedule />
        <Counters />
        <DedicatedAgent />
        <GoogleReviews banner="/assets/img/about-us/reviews-bg.webp" />
        <Subscribe />
      </Box>
    </>
  );
};

export default Home;

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { connect } from "react-redux";
import { flightSearchProps, loaderProps, reducerProps } from "src/interfaces";
import { FilterSectionSubTitle, FilterSectionTitle } from "./components";
import SectionContainer from "../../components/SectionContainer";
import {
  filterDataExtractor,
  filterListData,
  sortingDataExtractor,
  stopOptions,
} from "./constants";
import {
  fetchCityAirportDictionaries,
  fetchFlights,
  removeLoader,
  setFilteredFlights,
  setLoader,
  setSearchModel,
} from "src/store";
import { Checkbox } from "src/components";
import { useEffect, useState } from "react";
import { timeLabel, timeDifference } from "src/helpers";
import Slider from "src/components/Slider";
import Modal from "src/components/Modal";
import moment from "moment";
import { PrimaryButton } from "src/components/Buttons";
import Label from "src/components/Label";

const Component = ({
  data = [],
  filteredData = [],
  list,
  dictionaries,
  cityAirportDictionaries = {},
  model,
  setModel,
  fetchFlightOffers,
  fetchCityAirportDictionaries,
  setLoader,
  removeLoader,
  setFilteredData,
  filterModal,
  setFilterModal,
  setTabs,
}: {
  data: any[];
  filteredData: any[];
  list: any;
  dictionaries: any;
  cityAirportDictionaries: any;
  model: flightSearchProps;
  setModel: (payload: flightSearchProps) => void;
  fetchFlightOffers: (payload: flightSearchProps) => void;
  fetchCityAirportDictionaries: (payload: {
    codes: any[];
    type: "A" | "C";
  }) => void;
  setLoader: (payload: loaderProps) => void;
  removeLoader: () => void;
  setFilteredData: (payload: any) => void;
  filterModal?: boolean;
  setFilterModal?: Function;
  setTabs?: Function;
}) => {
  const isMobile = document?.body?.clientWidth <= 991;
  const theme = useTheme();

  const [departingAirportOptions, setDepartingAirportOptions] = useState<any[]>(
    []
  );
  const [arrivalAirportOptions, setArrivalAirportOptions] = useState<any[]>([]);

  const [minOutboundTime, setMinOutboundTime] = useState<string>(""); // in HH:MM:SS

  const [sliderLabels, setSliderLabels] = useState({
    duration: 0,
    layover: [0, 0],
    outbound: [0, 0],
  });

  const [maxOutboundValue, setMaxOutboundValue] = useState<number>(0);

  const [minDurationValue, setMinDurationValue] = useState<number>(0);
  const [maxDurationValue, setMaxDurationValue] = useState<number>(0);

  const [maxLayoverValue, setMaxLayoverValue] = useState<number>(0);

  const [carriers, setCarriers] = useState<any>({});
  const [carrierCodes, setCarrierCodes] = useState<string[]>([]);

  // for mobile view start //
  const [localFiltering, setLocalFiltering] = useState<boolean>(false);
  const [filtering, setFiltering] = useState<boolean>(false);
  // for mobile view end //

  useEffect(() => {
    if (model?.filtering && data?.length > 0) return;
    if (data?.length === 0) return;

    const {
      departure,
      arrival,
      minDeparture,
      maxDeparture,
      minDuration,
      maxDuration,
      maxLayover,
      initialDepartureCarriers,
      cheapestPrice,
      quickestPrice,
      bestPrice,
    }: any = filterDataExtractor(data, dictionaries) || {};
    // tabs
    setTabs &&
      setTabs([
        { title: "Cheapest", subTitle: cheapestPrice, value: "price" },
        { title: "Quickest", subTitle: quickestPrice, value: "time" },
        { title: "Best", subTitle: bestPrice, value: "price&time" },
      ]);

    // carriers
    setCarrierCodes(initialDepartureCarriers);

    // duration

    setMinDurationValue(minDuration);
    setMaxDurationValue(maxDuration);

    // outbound
    setMinOutboundTime(minDeparture);
    const outboundMaxValue = timeDifference(
      maxDeparture,
      minDeparture,
      "difference",
      true
    ) as number;

    setMaxOutboundValue(outboundMaxValue);

    // layover
    setMaxLayoverValue(maxLayover);

    setModel({
      ...model,
      outbound: [0, outboundMaxValue],
      duration: maxDuration,
      layover: [0, maxLayover],
    });

    // departure, arrival city codes //
    setDepartingAirportOptions(departure);
    setArrivalAirportOptions(arrival);
    fetchCityAirportDictionaries({
      codes: [...departure, ...arrival],
      type: "A",
    });
  }, [data]);

  useEffect(() => {
    if (model?.filtering || model?.localFiltering) {
      const { cheapestPrice, quickestPrice, bestPrice } =
        sortingDataExtractor(filteredData, dictionaries) || {};
      // tabs
      setTabs &&
        setTabs([
          { title: "Cheapest", subTitle: cheapestPrice, value: "price" },
          { title: "Quickest", subTitle: quickestPrice, value: "time" },
          { title: "Best", subTitle: bestPrice, value: "price&time" },
        ]);
    }
  }, [filteredData]);

  const handleChange = (event: any) => {
    setFiltering(true);
    setModel({
      ...model,
      filtering: !isMobile,
      [event?.target?.name]: event?.target?.value,
    });
  };

  useEffect(() => {
    if (!model?.filtering) {
      setCarriers(dictionaries?.carriers || {});
    }
  }, [dictionaries?.carriers]);

  const departingOptions = departingAirportOptions?.filter((airport) => {
    const airportDetail = cityAirportDictionaries["A" + airport];
    if (
      airportDetail?.data?.address?.cityName == model?.originName?.split(",")[0]
    ) {
      return airport;
    }
  });
  const arrivalOptions = arrivalAirportOptions?.filter((airport) => {
    const airportDetail = cityAirportDictionaries["A" + airport];
    if (
      airportDetail?.data?.address?.cityName ==
      model?.destinationName?.split(",")[0]
    ) {
      return airport;
    }
  });

  useEffect(() => {
    if (model?.localFiltering) {
      const filteredItems = filterListData(list, {
        duration: model?.duration,
        outbound: model?.outbound,
        outboundTime: model?.outboundTime,
        minOutboundTime,
        layover: model?.layover,
        departingAirports: model?.departingAirports,
        arrivalAirports: model?.arrivingAirports,
      } as any);
      setLocalFiltering(false);
      setModel({
        ...model,
        localFiltering: false,
      });
      setLoader({ visible: true, transparent: true });
      setTimeout(() => {
        setFilteredData(filteredItems);
        removeLoader();
      }, 1000);
    }
  }, [model?.duration, model?.layover, model?.outbound, model?.localFiltering]);

  useEffect(() => {
    if (
      !model?.origin ||
      !model?.destination ||
      !model?.departureDate ||
      !model?.filtering
    ) {
      return;
    }
    setFiltering(false);
    setLocalFiltering(false);
    let departureDate = moment(model?.departureDate).format("YYYY-MM-DD");
    let returnDate =
      model?.type === "round" && model?.returnDate
        ? moment(model?.returnDate).format("YYYY-MM-DD")
        : "";
    const payload: flightSearchProps = {
      ...model,
      nonStop: model?.maxNumberOfConnections == 0,
      departureDate,
      returnDate,
      minOutboundTime,
    } as any;
    setModel(payload);
    fetchFlightOffers(payload);
  }, [
    model?.includedCheckedBagsOnly,
    model?.maxNumberOfConnections,
    model?.maxFlightTime,
    model?.includedCarrierCodes?.length,
    model?.filtering,
  ]);

  const filterUI = (
    <Box
      sx={{
        backgroundColor: {
          md: theme?.colors?.alpha?.gray[10],
          xs: theme?.colors?.alpha?.white[100],
        },
        border: `1px solid ${theme?.colors?.alpha?.gray[30]}`,
        pt: 4,
        pb: { xs: 2, md: 4 },
      }}
    >
      <SectionContainer sx={{ mb: 2 }}>
        <FilterSectionTitle title={"Stops"} />
        <Box sx={{ mt: 2 }}>
          <RadioGroup
            row
            value={model?.maxNumberOfConnections?.toString() || ""}
            name="maxNumberOfConnections"
            onChange={handleChange}
          >
            {stopOptions?.map((option, optionIndex) => (
              <FormControlLabel
                key={optionIndex}
                value={option?.value}
                control={
                  <Radio
                    disabled={data?.length === 0}
                    color="secondary"
                    size="small"
                  />
                }
                label={<Box sx={{ fontSize: 12 }}>{option?.label}</Box>}
                labelPlacement="bottom"
              />
            ))}
          </RadioGroup>
        </Box>
      </SectionContainer>
      <SectionContainer sx={{ mb: 2 }}>
        <FilterSectionTitle title={"Duration"} />
        <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
          <FilterSectionSubTitle title="Maximum Flight Duration" />
          <Slider
            label={
              <Typography sx={{ textAlign: "right" }}>
                {timeLabel(model?.duration as number, null, "hm")}
              </Typography>
            }
            disabled={!maxDurationValue || data?.length === 0}
            min={minDurationValue}
            max={maxDurationValue}
            onChange={(event, value) => {
              setModel({ ...model, duration: value });
            }}
            onChangeCommitted={(event, value) => {
              setLocalFiltering(true);
              setModel({ ...model, localFiltering: !isMobile });
            }}
            defaultValue={model?.duration}
            value={model?.duration}
            valueLabelDisplay="off"
            valueLabelFormat={(value, index) => {
              return <Box>{timeLabel(value, null, "hm")}</Box>;
            }}
          />
        </Box>
        <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
          <FilterSectionSubTitle title="Layover" />
          <Slider
            label={
              <Typography sx={{ textAlign: "right" }}>
                {`${timeLabel(model?.layover[0], "00:00", "hm")} to
              ${timeLabel(model?.layover[1], "00:00", "hm")}`}
              </Typography>
            }
            disabled={
              data?.length === 0 ||
              model?.nonStop ||
              model?.maxNumberOfConnections === 0
            }
            max={maxLayoverValue}
            onChange={(event, value) => setModel({ ...model, layover: value })}
            onChangeCommitted={(event, value) => {
              setLocalFiltering(true);
              setModel({ ...model, localFiltering: !isMobile });
            }}
            defaultValue={model?.layover}
            value={model?.layover}
            valueLabelDisplay="off"
            valueLabelFormat={(value, index) => {
              return <Box>{timeLabel(value, "00:00", "hm")}</Box>;
            }}
          />
        </Box>
      </SectionContainer>
      <SectionContainer sx={{ mb: 2 }}>
        <FilterSectionTitle title={"Time"} />
        <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
          <FilterSectionSubTitle title="Outbound" />
          <Slider
            label={
              <Typography sx={{ textAlign: "right" }}>
                {`${timeLabel(model?.outbound[0], minOutboundTime)} to
              ${timeLabel(model?.outbound[1], minOutboundTime)}`}
              </Typography>
            }
            disabled={!maxOutboundValue || data?.length === 0}
            max={maxOutboundValue}
            onChange={(event, value) => setModel({ ...model, outbound: value })}
            onChangeCommitted={(event, value) => {
              setLocalFiltering(true);
              setModel({ ...model, localFiltering: !isMobile });
            }}
            defaultValue={model?.outbound}
            value={model?.outbound}
            valueLabelDisplay="off"
            valueLabelFormat={(value, index) => {
              return <Box>{timeLabel(value, minOutboundTime)}</Box>;
            }}
          />
        </Box>
      </SectionContainer>
      {carrierCodes?.length > 1 && data?.length > 0 && (
        <SectionContainer sx={{ mb: 2 }}>
          <FilterSectionTitle title={"Airlines"} />
          <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
            {carrierCodes?.map((airline, airlineIndex) => (
              <Box key={airlineIndex} sx={{ width: "100%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={data?.length === 0}
                      color="secondary"
                      size="small"
                      value={airline}
                      checked={model?.includedCarrierCodes?.includes(airline)}
                      onChange={() => {
                        if (model?.includedCarrierCodes?.includes(airline)) {
                          setFiltering(true);
                          setModel({
                            ...model,
                            filtering: !isMobile,
                            includedCarrierCodes:
                              model?.includedCarrierCodes?.filter(
                                (ele) => ele != airline
                              ),
                          });
                        } else {
                          setFiltering(true);
                          setModel({
                            ...model,
                            filtering: !isMobile,
                            includedCarrierCodes: [
                              ...model?.includedCarrierCodes,
                              airline,
                            ],
                          });
                        }
                      }}
                    />
                  }
                  label={carriers[airline]}
                />
              </Box>
            ))}
          </Box>
        </SectionContainer>
      )}
      {(departingOptions?.length <= 1 && arrivalOptions?.length <= 1) ||
      data?.length <= 0 ? null : (
        <SectionContainer sx={{ mb: 2 }}>
          <FilterSectionTitle title={"Airports"} />
          {departingOptions?.length > 1 && (
            <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
              <FilterSectionSubTitle title="Departing From" />
              {departingOptions?.map((airport, airportIndex) => {
                const airportDetail = cityAirportDictionaries["A" + airport];
                return (
                  <Box key={airportIndex} sx={{ width: "100%", mb: 0.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={data?.length === 0}
                          color="secondary"
                          size="small"
                          value={airport}
                          checked={model?.departingAirports?.includes(airport)}
                          onChange={() => {
                            let arr = model?.departingAirports || [];
                            if (arr?.includes(airport)) {
                              arr = arr?.filter((ele) => ele != airport);
                            } else {
                              arr = [...arr, airport];
                            }
                            setLocalFiltering(true);
                            setModel({
                              ...model,
                              localFiltering: !isMobile,
                              departingAirports: arr,
                            });
                          }}
                        />
                      }
                      label={
                        <Box sx={{}}>
                          {!airportDetail
                            ? airport
                            : airportDetail?.data?.name +
                              " Airport, " +
                              airportDetail?.data?.address?.cityName}
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          )}
          {arrivalOptions?.length > 1 && (
            <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
              <FilterSectionSubTitle title="Arrival At" />
              {arrivalOptions?.map((airport, airportIndex) => {
                const airportDetail = cityAirportDictionaries["A" + airport];
                return (
                  <Box key={airportIndex} sx={{ width: "100%", mb: 0.5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={data?.length === 0}
                          color="secondary"
                          size="small"
                          value={airport}
                          checked={model?.arrivingAirports?.includes(airport)}
                          onChange={() => {
                            let arr = model?.arrivingAirports || [];
                            if (arr?.includes(airport)) {
                              arr = arr?.filter((ele) => ele != airport);
                            } else {
                              arr = [...arr, airport];
                            }
                            setLocalFiltering(true);
                            setModel({
                              ...model,
                              localFiltering: !isMobile,
                              arrivingAirports: arr,
                            });
                          }}
                        />
                      }
                      label={
                        <Box sx={{}}>
                          {!airportDetail
                            ? airport
                            : airportDetail?.data?.name +
                              " Airport, " +
                              airportDetail?.data?.address?.cityName}
                        </Box>
                      }
                    />
                  </Box>
                );
              })}
            </Box>
          )}
        </SectionContainer>
      )}
      <SectionContainer sx={{ mb: 2 }}>
        <FilterSectionTitle title={"Amenities"} />
        <Box sx={{ mt: 2, width: "80%", mx: "auto" }}>
          <FormControlLabel
            sx={{}}
            control={
              <Checkbox
                disabled={data?.length === 0}
                color="secondary"
                size="small"
                checked={model?.includedCheckedBagsOnly}
                onChange={() =>
                  handleChange({
                    target: {
                      name: "includedCheckedBagsOnly",
                      value: !model?.includedCheckedBagsOnly,
                    },
                  })
                }
              />
            }
            label="With Baggage Only"
          />
        </Box>
      </SectionContainer>
      <Box
        sx={{
          px: 3,
          mt: 1.5,
          display: { md: "none" },
        }}
      >
        <PrimaryButton
          variant="contained"
          // disabled={!filtering && !localFiltering}
          fullWidth
          onClick={() => {
            setFilterModal(false);
            setModel({
              ...model,
              filtering: filtering,
              localFiltering: localFiltering,
            });
          }}
        >
          Apply
        </PrimaryButton>
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ md: "block", xs: "none" }}>{filterUI}</Box>
      {setFilterModal && (
        <Modal open={filterModal} onClose={() => setFilterModal(false)}>
          {filterUI}
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = ({
  flights: { list, filteredList },
  searchModel,
  locations: { cityAirportDictionaries },
}: reducerProps) => ({
  data: list?.data,
  filteredData: filteredList?.data,
  list: list as any,
  dictionaries: list?.dictionaries,
  model: searchModel,
  cityAirportDictionaries,
});

const mapDispatchToProps = (dispatch) => ({
  setModel: (payload: any) => dispatch(setSearchModel(payload)),
  setLoader: (payload: any) => dispatch(setLoader(payload)),
  removeLoader: () => dispatch(removeLoader()),
  setFilteredData: (payload: any) => dispatch(setFilteredFlights(payload)),
  fetchFlightOffers: (payload: any) => dispatch(fetchFlights(payload)),
  fetchCityAirportDictionaries: (payload: any) =>
    dispatch(fetchCityAirportDictionaries(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Card from "../../../components/Card";
import { PrimaryButton } from "src/components/Buttons";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import SectionContainer from "src/components/SectionContainer";

const DedicatedAgent = () => {
  const theme = useTheme();
  return (
    <SectionContainer sx={{ mt: "4rem", mb: { md: "5rem" } }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Stack
          direction={{ md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Card
            icon={"/assets/img/about-us/prem.png"}
            alt="best travel agency in calgary & edmonton"
            imgSx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            sx={{
              mb: {
                md: 0,
                xs: "2rem !important",
              },
              position: "relative",
              bottom: { md: "-3rem" },
              boxShadow: "unset",
              width: { md: "35%" },
              padding: 0,
            }}
          />
          <Box width={{ md: "60%" }}>
            <SectionHeader sx={{ textAlign: "start" }}>
              Dedicated <HeaderSpan>TRAVEL AGENTS</HeaderSpan>
              <Box
                sx={{
                  fontSize: 16,
                  fontWeight: "600",
                  color: theme?.colors?.alpha?.black[70],
                }}
              >
                We craft unforgettable adventures
              </Box>
            </SectionHeader>
            <Box sx={{ width: "100%" }}>
              <Typography component="p" sx={{ textAlign: "justify", mb: 1 }}>
                In the vast landscape of travel, where the possibilities are as
                diverse as the destinations themselves, our team of dedicated
                travel agents in Calgary and Edmonton stand as the architects of
                unforgettable journeys. Beyond just trip planners, our
                professionals play a major role in transforming your travel
                aspirations into well-crafted and seamless experiences.
              </Typography>
              <Typography component="p" sx={{ textAlign: "justify", mb: 1 }}>
                One of the main advantages of booking your flight tickets with
                our travel agents lies in the wealth of knowledge they bring to
                the table. Travel experts at Millwoods possess a deep
                understanding of various destinations, from iconic landmarks to
                hidden gems tucked away in unknown corners. This knowledge is
                not confined to glossy brochures or online reviews; it is rooted
                in real experiences, personal connections, and a genuine love
                for exploration. It's this firsthand knowledge that allows our
                travel agents to offer guidance that goes beyond, providing
                travellers with a genuine sense of the culture, history, and
                knowledge of the places they plan to visit.
              </Typography>
            </Box>
            <PrimaryButton
              component={Link}
              href="/contact-us"
              sx={{ mt: { md: 2, xs: 1 } }}
            >
              Contact Us
            </PrimaryButton>
          </Box>
        </Stack>
      </Container>
    </SectionContainer>
  );
};

export default DedicatedAgent;

export const HANDLE_FETCH_AMADEUS_TOKEN = "HANDLE_FETCH_AMADEUS_TOKEN";
export const HANDLE_LOGIN = "HANDLE_LOGIN";
export const HANDLE_USER_VALIDATION = "HANDLE_USER_VALIDATION";
export const HANDLE_USER_VERIFICATION_EMAIL_RESEND = "HANDLE_USER_VERIFICATION_EMAIL_RESEND";
export const HANDLE_USER_VERIFY_EMAIL = "HANDLE_USER_VERIFY_EMAIL";
export const HANDLE_FORGOT_PASSWORD = "HANDLE_FORGOT_PASSWORD";
export const HANDLE_VERIFY_RESET_PASSWORD = "HANDLE_VERIFY_RESET_PASSWORD";
export const HANDLE_RESET_PASSWORD = "HANDLE_RESET_PASSWORD";
export const HANDLE_CHANGE_PASSWORD = "HANDLE_CHANGE_PASSWORD";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const REMOVE_AUTH_USER = "REMOVE_AUTH_USER";
export const SET_AMADEUS_TOKEN = "SET_AMADEUS_TOKEN";
export const REMOVE_AMADEUS_TOKEN = "REMOVE_AMADEUS_TOKEN";

import { Box, Stack, Link, Typography, useTheme } from "@mui/material";
import Card from "src/components/Card";

const imgHeight = { md: 360, xs: 300 };
const VacationCard = ({ item, index }) => {
  const theme = useTheme();
  return (
    <Box key={index} component={Link} href={"/vacations/" + item?.url}>
      <Card
        {...item}
        sx={{
          mb: { md: 2, xs: 2 },
          padding: 0,
          borderRadius: 0.2,
          position: "relative",
          width: imgHeight,
          boxShadow: "unset",
          cursor: "pointer",
        }}
        imgSx={{ width: "100%", height: imgHeight }}
      >
        <Box
          mt={"8%"}
          height={imgHeight}
          sx={{
            position: "absolute",
            width: "80%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              cursor: "pointer",
              height: { md: imgHeight?.md / 4, xs: imgHeight?.xs / 4 },
              backgroundColor: theme?.colors?.alpha?.black[70],
              color: theme?.colors?.alpha?.white[100],
              position: "relative",
              transition: "all 0.5s",
            }}
          >
            <Box px={1} py={0.5} my={"auto"} width="100%">
              <Typography sx={{ color: "#74CDFF", textAlign: "left" }}>
                {item?.origin} to
              </Typography>
              <Typography
                sx={{ fontSize: 25, width: "70%", textAlign: "right" }}
              >
                {item?.destination}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: "right", top: 5.9, position: "relative" }}>
            <Box
              component="span"
              sx={{
                backgroundColor: "#FF4358",
                color: theme?.colors?.alpha?.white[100],
                p: 1,
              }}
            >
              {item?.duration}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundImage: `url(assets/icons/star.svg)`,
            backgroundSize: "contain",
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            right: 5,
            bottom: 15,
            width: { md: imgHeight.md / 3, xs: imgHeight?.xs / 3 },
            height: { md: imgHeight.md / 3, xs: imgHeight?.xs / 3 },
          }}
        >
          <Typography
            sx={{ fontSize: 10, color: theme?.colors?.alpha?.white[100] }}
          >
            Starting from
          </Typography>
          <Typography
            sx={{
              fontSize: { md: 25, xs: 20 },
              textAlign: "right",
              color: theme?.colors?.alpha?.white[100],
            }}
          >
            <Typography component="span" sx={{ fontSize: 14, mr: 0.3 }}>
              $
            </Typography>
            {item?.price}
          </Typography>
        </Box>
        <Stack
          direction="row"
          width="100%"
          sx={{ position: "relative", marginTop: -5.4 }}
        >
          <Box
            component="span"
            sx={{
              position: "relative",
              width: "100%",
              mt: "auto",
              lineHeight: 0,
              top: 1.5,
              backgroundColor: theme?.colors?.alpha?.white[100],
              textAlign: "start",
            }}
          >
            <Typography
              src="/assets/icons/multi-colors.svg"
              component="img"
              sx={{
                width: "70%",
                height: "100%",
                position: "relative",
                objectFit: "contain",
              }}
            />
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default VacationCard;

import DefaultBreadcrumbs from "@mui/material/Breadcrumbs";
import SectionHeader, { HeaderSpan } from "../SectionHeader";
import Banner from "../Banner";

const Breadcrumbs = ({
  title = [],
  banner,
}: {
  title?: string[];
  banner?: { md: string; xs: string };
}) => {
  return (
    <Banner
      url={{
        md: banner?.md || "/assets/img/bread-crumb.webp",
        xs: banner?.xs || "/assets/img/bread-crumb-mobile.webp",
      }}
      imageSx={{
        height: {
          md: 200,
          xs: 120,
          // backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        },
      }}
      colorSx={{ py: 0, backgroundImage: "unset" }}
      containerSx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <DefaultBreadcrumbs sx={{ div: { mb: 0 } }}>
        <SectionHeader sx={{ color: "white", fontSize: { md: 50, xs: 30 } }}>
          {title?.map((item, index) =>
            index % 2 === 0 ? `${item} ` : <HeaderSpan>{item}</HeaderSpan>
          )}
        </SectionHeader>
      </DefaultBreadcrumbs>
    </Banner>
  );
};
export default Breadcrumbs;

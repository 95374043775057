export const cards = [
    {
      origin: "Edmonton",
      destination: "Cancun",
      bottomTitle: "",
      price: "500",
      duration: "3 Nights",
      icon: "/assets/vacations/1.webp",
      alt:'cheap vacations from calgary',
      url:'edmonton-to-cancun-packages'  
    },
    {
      origin: "Edmonton",
      destination: "Las Vegas",
      bottomTitle: "",
      price: "400",
      duration: "3 Nights",
      icon: "/assets/vacations/2.webp",
      alt:'cheapest vacation packages',
      url:'edmonton-to-las-vegas-packages'
    },
    {
      origin: "Edmonton",
      destination: "Los Cabos",
      bottomTitle: "",
      price: "500",
      duration: "3 Nights",
      icon: "/assets/vacations/3.webp",
      alt:'cheap vacations from edmonton',
      url:'edmonton-to-los-cabos-packages'
    },
    {
      origin: "Calgary",
      destination: "Cancun",
      bottomTitle: "",
      price: "500",
      duration: "3 Nights",
      icon: "/assets/vacations/4.webp",
      alt:'vacation travel agency',
      url:'calgary-to-cancun-packages'
    },
    {
      origin: "Calgary",
      destination: "Las Vegas",
      bottomTitle: "",
      price: "400",
      duration: "3 Nights",
      icon: "/assets/vacations/5.webp",
      alt:'cheap vacation packages calgary',
      url: "calgary-to-las-vegas"
    },
    {
      origin: "Calgary",
      destination: "Los Cabos",
      bottomTitle: "",
      price: "500",
      duration: "3 Nights",
      icon: "/assets/vacations/6.webp",
      alt:'cheap vacation packages edmonton',
      url: "calgary-to-los-cabos-packages"
    },
  ];
import React, { Suspense, lazy } from "react";
import { Navigate } from "react-router-dom";
import { RouteObject } from "react-router";
import SuspenseLoader from "src/components/SuspenseLoader";
import FlightLayout from "./layouts/FlightLayout";
import BaseLayout from "./layouts/BaseLayout";
import  Home from "src/views/home";
import  AboutUs from "src/views/aboutUs";
import  ContactUs from "src/views/contactUs";
import  Blogs from "src/views/blogs";
import  BlogDetail from "src/views/blogs/detail";
import  FlightsList from "src/views/flightsList";
import  Vacations from "src/views/popularVacations";
import  VacationDetail from "src/views/popularVacations/Vacations/detail"
import  BookingSuccess from "src/views/bookingSuccess";
import  BookingRules from "src/views/bookingRules";
import  PrivacyPolicy from "src/views/privacyPolicy";

// const Loader = (Component) => (props) =>
// (
//   <Suspense fallback={<SuspenseLoader />}>
//     <Component {...props} />
//   </Suspense>
// );


// const ContactUs = Loader(lazy(() => import("src/views/contactUs")));

const routes = (role?: string, isLoggedIn: boolean = false): RouteObject[] => {
  let routesList: RouteObject[] = [
    {
      path: "",
      element: <FlightLayout />,
      children: [
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
        {
          path: "",
          element: <Home />,
        },
        {
          path: "flights",
          element: <FlightsList />,
        },
      ],
    },
    {
      path: "",
      element: <BaseLayout />,
      children: [
        {
          path: "*",
          element: <Navigate to="" replace />,
        },
        {
          path: "vacations",
          element: <Vacations />,
        },
        {
          path: "vacations/:id",
          element: <VacationDetail />,
        },
        // {
        //   path: "flight-booking",
        //   element: <FlightBooking />,
        // },
        {
          path: "flight-booking-success",
          element: <BookingSuccess />,
        },
        {
          path: "blog",
          element: <Blogs />,
        },
        {
          path: "blog/article/:id",
          element: <BlogDetail /> ,
        },
        {
          path: "about-us",
          element: <AboutUs />,
        },
        {
          path: "contact-us",
          element: <ContactUs />,
        },
        // {
        //   path: "booking-rules",
        //   element: <BookingRules />,
        // },
        {
          path: "terms",
          element: <BookingRules />,
          // element: <TermsAndConditions />,
        },
        {
          path: "privacy-policy",
          element: <PrivacyPolicy />,
        },
      ],
    },
  ];
  return routesList;
};

export default routes;

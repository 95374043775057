import type { PayloadAction } from "@reduxjs/toolkit";
import { SET_LOADER, REMOVE_LOADER } from "./types";
import { loaderProps } from "../../interfaces";

const initialeState: loaderProps = {
  visible: false,
  transparent: false,
  type: "main",
};
export const loader = (
  state = initialeState,
  action: PayloadAction<loaderProps> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_LOADER: {
      return payload;
    }
    case REMOVE_LOADER: {
      return initialeState;
    }
    default: {
      return state;
    }
  }
};

import type { PayloadAction } from "@reduxjs/toolkit";
import {
  SET_SEARCH_CITIES,
  REMOVE_SEARCH_CITIES,
  SET_CITY_AIRPORT_DICTIONARIES,
  REMOVE_CITY_AIRPORT_DICTIONARIES,
  SET_SEARCH_CITIES_LOADER,
} from "./types";
import { locationReducer } from "src/interfaces";

const initialeState: locationReducer = {
  origins: [],
  destinations: [],
  cityAirportDictionaries: {},
  loading: false,
};
export const locations = (
  state = initialeState,
  action: PayloadAction<any> = null
): locationReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_SEARCH_CITIES: {
      return {
        ...state,
        [payload?.key]: payload?.cities || [],
        loading: payload?.loading,
      };
    }
    case SET_CITY_AIRPORT_DICTIONARIES: {
      return { ...state, cityAirportDictionaries: payload };
    }
    case REMOVE_CITY_AIRPORT_DICTIONARIES: {
      return { ...state, cityAirportDictionaries: {} };
    }
    case REMOVE_SEARCH_CITIES: {
      return {
        ...state,
        [payload]: [],
        loading: false,
      };
    }
    case SET_SEARCH_CITIES_LOADER: {
      return {
        ...state,
        loading: payload,
      };
    }
    default: {
      return state;
    }
  }
};

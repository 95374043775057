import { Box, Container, Stack, useTheme } from "@mui/material";
import { connect } from "react-redux";
import { PrimaryButton } from "src/components/Buttons";
import { reducerProps } from "src/interfaces";
import { removeLoader } from "src/store";

const Component = ({
  booked = {},
  removeLoader,
}: {
  booked: any;
  removeLoader: Function;
}) => {
  const theme = useTheme();
  
  removeLoader();
  return (
    <>
      <Container
        maxWidth="lg"
        sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}
      >
        <Stack
          alignItems="center"
          alignSelf="center"
          sx={{
            width: { md: "50%", xs: "100%" },
            my: { md: 8, xs: 5 },
            borderRadius: 3,
            border: `2px dashed ${theme?.colors?.alpha?.secondary[50]}`,
            p: 5,
          }}
        >
          <Box>Booking details have been submitted</Box>
          <Box component="h2" sx={{ my: 1 }}>
            Successfully !
          </Box>
          <Box
            component="img"
            src={"/assets/icons/flight-icon.svg"}
            sx={{ width: 90, my: 3 }}
          />
          <Box sx={{ my: 3 }}>Our representative will contact you soon</Box>
          <PrimaryButton href="/" sx={{ borderRadius: 0, px: 1.5 }}>
            Go for Another Booking!
          </PrimaryButton>
        </Stack>
        {/* <Box component="h2">
          Reference No.:-{"  "}
          {booked?.data?.associatedRecords[0]?.reference}
        </Box> */}
      </Container>
    </>
  );
};

const mapStateToProps = ({ flights: { booked } }: reducerProps) => ({ booked });

const mapDispatchToProps = (dispatch) => ({
  removeLoader: () => dispatch(removeLoader()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

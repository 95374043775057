import type { PayloadAction } from "@reduxjs/toolkit";
import {
  SET_FLIGHTS,
  REMOVE_FLIGHTS,
  SET_DEAL_FLIGHTS,
  SET_FILTERED_FLIGHTS,
  SET_BOOKING_FLIGHT,
  REMOVE_BOOKING_FLIGHT,
  SET_BOOKED_FLIGHT,
  REMOVE_BOOKED_FLIGHT,
} from "./types";
import { flightReducer } from "src/interfaces";
import { addDays } from "date-fns";

const initialeState: flightReducer = {
  list: null,
  filteredList: null,
  deals: {},
  booking: null,
  booked: null,
};

export const flights = (
  state = initialeState,
  action: PayloadAction<any> = null
): flightReducer => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_FLIGHTS: {
      return { ...state, list: payload, filteredList: payload };
    }
    case SET_FILTERED_FLIGHTS: {
      return { ...state, filteredList: payload };
    }
    case REMOVE_FLIGHTS: {
      return { ...state, list: null, filteredList: null };
    }
    case SET_DEAL_FLIGHTS: {
      return { ...state, deals: payload };
    }
    case SET_BOOKING_FLIGHT: {
      return { ...state, booking: payload };
    }
    case REMOVE_BOOKING_FLIGHT: {
      return { ...state, booking: null };
    }
    case SET_BOOKED_FLIGHT: {
      return { ...state, booked: payload };
    }
    case REMOVE_BOOKED_FLIGHT: {
      return { ...state, booked: null };
    }
    default: {
      return state;
    }
  }
};

import {
  Box,
  ButtonProps,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Column } from "../../flightsList/components";
import { PrimaryButton } from "src/components/Buttons";
import PriceDescription from "../priceDescription";
import { flightDataProps } from "src/interfaces";

const bookNow = (
  <Typography
    sx={{ m: "auto" }}
    component="img"
    src="/assets/icons/booknow.svg"
  />
);
const callNow = (
  <Typography
    sx={{ m: "auto" }}
    component="img"
    src="/assets/icons/callnow.svg"
  />
);

const BookNowButton = (props: ButtonProps) => {
  return (
    <PrimaryButton
      variant="text"
      {...props}
      sx={{
        mt: 1.5,
        p: 0,
        width: "auto",
      }}
    >
      {bookNow}
    </PrimaryButton>
  );
};

const CallNowButton = (props: ButtonProps) => {
  return (
    <PrimaryButton variant="text" {...props} sx={{ mt: 1.5, p: 0 }}>
      {callNow}
    </PrimaryButton>
  );
};

export const PriceCardMobile = ({
  flightData,
  totalPrice,
  travelerPricingData,
  seatsDetail,
  isCanadaToCanada,
  onShowCancellationDescription = () => {},
  onBookClick = () => {},
  onCallClick = () => {},
}: {
  flightData: flightDataProps;
  onShowCancellationDescription: () => void;
  onBookClick: (item: flightDataProps) => void;
  onCallClick: () => void;
  totalPrice: string;
  travelerPricingData: any;
  seatsDetail: any;
  isCanadaToCanada: boolean;
}) => {
  const theme = useTheme();

  return (
    <Column
      sx={{
        textAlign: "center",
        width: { md: "20%", xs: "100%" },
        display: { md: "none", xs: "block" },
        mt: { md: 0, xs: 1 },
        px: 2,
        mr: 0,
        backgroundColor: theme?.colors?.secondary.main,
      }}
    >
      <Stack
        spacing={2}
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          py: 1,
          color: "white",
        }}
      >
        {/* <Box sx={{ fontSize: 15 }}>
          ${" "}
          <Box component="span" sx={{ fontSize: 25 }}>
            {totalPrice?.toString()?.split(".")[0]}.
          </Box>
          {totalPrice?.toString()?.split(".")[1]}
        </Box> */}
        <Box sx={{}}>
          <Box
            sx={{
              fontSize: 14,
            }}
          >
            {seatsDetail}
          </Box>
          {/* <Stack
            direction="row"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              color: theme?.colors?.info?.light,
            }}
          >
            <Box
              component="div"
              onClick={onShowCancellationDescription}
              sx={{ fontSize: 11, cursor: "pointer" }}
            >
              Free Cancellation
            </Box>
          </Stack> */}
        </Box>
        <BookNowButton onClick={() => onBookClick(flightData)} />
        <CallNowButton onClick={() => onCallClick()} />
      </Stack>
      <PriceDescription
        travelerPricingData={travelerPricingData}
        isCanadaToCanada={isCanadaToCanada}
        priceData={flightData?.price}
        containerSx={{
          width: "75%",
          ml: "auto",
          py: 0.5,
          color: theme?.colors?.alpha?.white[100],
        }}
        lineSx={{
          width: "92%",
          mx: "auto",
          my: 0.5,
          height: 2,
          backgroundColor: theme?.colors?.info?.main,
        }}
      />
    </Column>
  );
};

export const PriceCardDesktop = ({
  flightData,
  totalPrice,
  travelerPricingData,
  seatsDetail,
  isCanadaToCanada,
  itineraryIndex,
  onBookClick = () => {},
  onCallClick = () => {},
  onHowToBookClick = () => {},
}: {
  flightData: flightDataProps;
  itineraryIndex: number;
  onBookClick: (item: flightDataProps) => void;
  onCallClick: () => void;
  onHowToBookClick: () => void;
  totalPrice: string;
  travelerPricingData: any;
  seatsDetail: any;
  isCanadaToCanada: boolean;
}) => {
  const theme = useTheme();

  return (
    <Column
      sx={{
        textAlign: "center",
        width: { xl: "15%", lg: "20%", md: "20%", xs: "100%" },
        display: { md: "block", xs: "none" },
        mr: 0,
        zIndex: 1,
      }}
    >
      {itineraryIndex === 0 && (
        <Box
          sx={{
            width: "100%",
            py: 3,
            color: theme?.colors?.secondary?.main,
            backgroundColor: theme?.colors?.alpha.gray[30],
          }}
        >
          {/* <Box sx={{ fontSize: 15 }}>
            ${" "}
            <Box component="span" sx={{ fontSize: 25 }}>
              {totalPrice?.toString()?.split(".")[0]}.
            </Box>
            {totalPrice?.toString()?.split(".")[1]}
            <Tooltip
              arrow
              title={
                <PriceDescription
                  travelerPricingData={travelerPricingData}
                  isCanadaToCanada={isCanadaToCanada}
                  priceData={flightData?.price}
                />
              }
            >
              <InfoIcon
                sx={{
                  fontSize: 13,
                  ml: 0.5,
                  mt: "auto",
                }}
                color="secondary"
              />
            </Tooltip>
          </Box> */}
          <Box
            sx={{
              mt: 1,
              fontSize: 20,
              backgroundColor: theme?.colors?.secondary?.main,
              color: theme?.colors?.alpha?.white[100],
            }}
          >
            {seatsDetail}
          </Box>
          {/* <Stack> */}
          <BookNowButton onClick={() => onBookClick(flightData)} />
          <CallNowButton onClick={() => onCallClick()} />
          {/* </Stack> */}
          <Stack
            direction="row"
            component="div"
            onClick={onHowToBookClick}
            sx={{
              mt: 1.5,
              alignItems: "center",
              mx: { lg: "25%", md: "15%" },
              width: "100%",
              color: theme?.colors?.alpha?.secondary[100],
              cursor: "pointer",
            }}
          >
            <InfoIcon fontSize="small" />
            <Box sx={{ ml: 0.2, mt: 0.2 }}>How to book</Box>
          </Stack>
          {/* <Stack
            direction="row"
            sx={{
              mt: 1.5,
              alignItems: "center",
              mx: "8%",
              width: "100%",
              color: theme?.colors?.alpha?.secondary[100],
            }}
          >
            <Tooltip
              arrow
              title={
                <Box sx={{ width: 200, p: 1 }}>
                  Book now and if your plans change, you can cancel for no
                  charge by calling us within 3 hours or for fee within 12 hours
                  of booking.
                </Box>
              }
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
            <Box sx={{ ml: 0.2, mt: 0.2 }}>Free Cancellation</Box>
          </Stack> */}
        </Box>
      )}
    </Column>
  );
};

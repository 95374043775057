import { Box, BoxProps } from "@mui/material";

const SectionContainer = (props: BoxProps) => {
  return (
    <Box
      {...props}
      sx={{
        mb: { md: "6rem", xs: "3rem" },
        position: "relative",
        ...props?.sx,
      }}
    >
      {props?.children}
    </Box>
  );
};

export default SectionContainer;

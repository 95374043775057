import { flightSearchProps } from "src/interfaces";
const day = new Date().getDate() + 1;
const month = new Date().getMonth() + 1;
const year = new Date().getFullYear();

let date = `${year}-${month < 10 ? "0" + month : month}-${
  day < 10 ? "0" + day : day
}`;

const origin1 = "YEG";
const destination1 = "DEL";
const originName1 = "Edmonton";
const destinationName1 = "New Delhi";
const origin2 = "YYC";
const destination2 = "DEL";
const originName2 = "Calgary";
const destinationName2 = "New Delhi";
const origin3 = "YEG";
const destination3 = "DXB";
const originName3 = "Edmonton";
const destinationName3 = "Dubai";
const origin4 = "YYC";
const destination4 = "DXB";
const originName4 = "Calgary";
const destinationName4 = "Dubai";

export const cards = [
  {
    origin: origin1,
    destination: destination1,
    originName: originName1,
    destinationName: destinationName1,
    backgroundColor: "primary",
    icon: "/assets/deals/1.webp",
    alt:"travel agents in Calgary & Edmonton"
  },
  {
    origin: origin2,
    destination: destination2,
    originName: originName2,
    destinationName: destinationName2,
    backgroundColor: "secondary",
    icon: "/assets/deals/2.webp",
    alt:"best flight booking company in edmonton"
  },
  {
    origin: origin3,
    destination: destination3,
    originName: originName3,
    destinationName: destinationName3,
    backgroundColor: "primary",
    icon: "/assets/deals/3.webp",
    alt:"best flight booking company in Calgary"
  },
  {
    origin: origin4,
    destination: destination4,
    originName: originName4,
    destinationName: destinationName4,
    backgroundColor: "secondary",
    icon: "/assets/deals/4.webp",
    alt:"travel agency flight booking"
  },
];

export const payload: flightSearchProps | any = {
  type: "oneway",
  departureDate: date,
  adults: 1,
  travelClass: "ECONOMY",
  max: 1,
  originDestinations: [
    {
      origin: origin1,
      destination: destination1,
    },
    {
      origin: origin2,
      destination: destination2,
    },
    {
      origin: origin3,
      destination: destination3,
    },
    {
      origin: origin4,
      destination: destination4,
    },
  ],
};

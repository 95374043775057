import { Box } from "@mui/material";
import Breadcrumbs from "src/components/Breadcrumb";
import Blogs from "./blogs";
import Subscribe from "../components/Subscribe";

const Home = () => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Breadcrumbs
          title={["Our", "Blog"]}
          banner={{
            md: "/assets/img/vacations-banner.webp",
            xs: "/assets/img/vacations-banner-mobile.webp",
          }}
        />
        <Blogs />
        <Subscribe />
      </Box>
    </>
  );
};

export default Home;

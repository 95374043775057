export const cards = (isHome) => {
  const imgUrl = isHome ? "-home" : "-home";
  return [
    {
      description: "Team",
      icon: "/assets/icons/totalusers.svg",
      max: 22,
      titleEnd: " +",
      backgroundColor: "primary",
    },
    {
      description: "Years",
      icon: `/assets/icons/year-icon${imgUrl}.svg`,
      max: 7,
      titleEnd: " +",
      backgroundColor: "secondary",
    },
    {
      description: "Locations",
      icon: "/assets/icons/locations.svg",
      max: 2,
      backgroundColor: "primary",
    },
    {
      description: "Customers Served",
      icon: `/assets/icons/customers.svg`,
      max: 4500 , 
      titleEnd: " +",
      backgroundColor: "secondary",
    },
  ];
};

import { Box, Container, Stack, alpha, lighten, useTheme } from "@mui/material";
import { connect } from "react-redux";
import {
  dictionariesProps,
  flightDataProps,
  flightSearchProps,
  loaderProps,
  reducerProps,
  routeProps,
} from "src/interfaces";
import {
  fetchCityAirportDictionaries,
  removeLoader,
  setBookingFlight,
  setCurrentRoute,
  setLoader,
  setActiveTab,
  setFilterModal,
  setShowDetails,
} from "src/store";
import { useEffect, useState } from "react";
import Filters from "./Filters";
import { FlightPagination, NoFlights, tabProps } from "./components";
import SearchArea from "../components/searchArea";
import RenderItem from "../components/flightItem";
import RenderDetail from "../components/flightDetail";
import Modal from "src/components/Modal";
// import Sorting from "./Sorting";
// import {
//   sortByDuration,
//   sortByPrice,
//   sortByPriceAndDuration,
// } from "./constants";
import SpecialDealCard from "../components/specialDealCard";
import BookingModal from "./BookingModal";
import BookingStepsModal from "./BookingStepsModal";
import CallModal from "./CallModal";
import { sortByDuration } from "./constants";
import Faqs from "../components/faqs";
import { faqsFlights } from "../contactUs/constants";

const perPageCount = 5;

const Component = ({
  filteredData = [],
  data = [],
  dictionaries,
  cityAirportDictionaries,
  model,
  tempModel,
  fetchCityAirportDictionaries,
  setBookingFlight,
  setRoute,
  setLoader,
  removeLoader,
  showDetails,
  setShowDetails,
  filterModal,
  setFilterModal,
  activeTab = "price",
  setActiveTab,
}: {
  filteredData: flightDataProps[];
  data: flightDataProps[];
  dictionaries: dictionariesProps;
  cityAirportDictionaries: any;
  model: flightSearchProps;
  tempModel: flightSearchProps;
  fetchCityAirportDictionaries: (payload: {
    codes: any[];
    type: "A" | "C";
  }) => void;
  setBookingFlight: (item: any) => void;
  setRoute: (payload: routeProps) => void;
  setLoader: (payload: loaderProps) => void;
  removeLoader: () => void;
  showDetails: number[];
  setShowDetails: (payload: number[]) => void;
  filterModal: boolean;
  setFilterModal: (payload: boolean) => void;
  activeTab: "price" | "time" | "price&time";
  setActiveTab: (payload: "price" | "time" | "price&time") => void;
}) => {
  const theme = useTheme();

  const totalPage: any = Math.ceil(
    (filteredData?.length / perPageCount) as any
  );

  const [freeCancellation, setFreeCancellation] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    setPage(1);
  }, [filteredData]);

  useEffect(() => {
    const codes = Object.keys(dictionaries?.locations || {});
    fetchCityAirportDictionaries({ codes: codes, type: "A" });
  }, [dictionaries?.locations]);

  const currentPageCount = (page - 1) * perPageCount;

  const bodyWidth = document.body.clientWidth;
  const [conntainerPadding, setContainerPadding] = useState({});
  useEffect(() => {
    if (bodyWidth < 991) {
      setContainerPadding({ px: 0 });
    } else {
      setContainerPadding({});
    }
  }, [bodyWidth]);
  const [showBookingStepsModal, setShowBookingStepsModal] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showCallModal, setShowCallModal] = useState(false);
  const onBookClick = (item: any) => {
    setBookingFlight(item);
    setShowBookingModal(true);
    // setRoute({ path: "/flight-booking" });
  };
  const onCallClick = () => {
    setShowCallModal(true);
    // setRoute({ path: "/flight-booking" });
  };

  const tabList: tabProps[] = [
    { title: "Cheapest", subTitle: "", value: "price" },
    { title: "Quickest", subTitle: "", value: "time" },
    { title: "Best", subTitle: "", value: "price&time" },
  ];

  const [tabs, setTabs] = useState<tabProps[]>([...tabList]);

  useEffect(() => {
    // setLoader({ transparent: true });
    // setTimeout(() => {
    //   removeLoader();
    // }, 700);
  }, [activeTab]);

  return (
    <Box sx={{ mb: 3.5 }}>
      <Box sx={{ display: { md: "none", xs: "block" } }}>
        <SearchArea />
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          ...conntainerPadding,
          mt: { md: 3, xs: 1 },
          pt: {
            xs: data?.length == 0 || !data || tempModel?.searching ? 0 : 10,
            md: 0,
          },
        }}
      >
        {data?.length === 0 ? (
          <NoFlights mt={20} />
        ) : (
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between", my: { md: 2, xs: 0 } }}
          >
            <Box
              sx={{
                width: { md: "25%", xl: "20%" },
                display: { md: "block", xs: "none" },
              }}
            >
              <Filters
                filterModal={filterModal}
                setFilterModal={setFilterModal}
                setTabs={setTabs}
              />
            </Box>
            <Box
              sx={{
                width: { xl: "78%", md: "73%", xs: "100%" },
                position: "relative",
              }}
            >
              {/* {filteredData?.length > 0 && (
              <Box
                sx={{
                  px: { md: 0, xs: 0.5, textAlign: "center", width: "100%" },
                }}
              >
                <Sorting
                  tabs={tabs}
                  activeTab={activeTab}
                  onChangeTab={(value) => {
                    setActiveTab(value);
                    setPage(1);
                  }}
                />
              </Box>
            )} */}
              {filteredData?.length === 0 ? (
                <NoFlights />
              ) : (
                filteredData
                  // ?.sort(
                  //   activeTab === "price"
                  //     ? sortByPrice
                  //     : activeTab === "time"
                  //     ? sortByDuration
                  //     : sortByPriceAndDuration
                  // )
                  // ?.sort(sortByDuration)
                  ?.slice(currentPageCount, currentPageCount + perPageCount)
                  ?.map((item, index) => {
                    return (
                      <>
                        {index > 0 &&
                          index % 2 === 0 &&
                          filteredData?.slice(
                            currentPageCount,
                            currentPageCount + perPageCount
                          )?.length >= 2 && <SpecialDealCard />}
                        <Box
                          key={index}
                          sx={{
                            mb: 0.5,
                            pt: { md: 1, xs: 1 },
                            pb: { md: 1, xs: 0.5 },
                            borderBottom: { md: "1px dashed gray" },
                            boxShadow:
                              theme.palette.mode === "dark"
                                ? `0 1px 0 ${alpha(
                                  lighten(theme.colors.primary.main, 0.7),
                                  0.15
                                )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                                : `0px 2px 8px -3px ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.2
                                )}, 0px 5px 22px -4px ${alpha(
                                  theme.colors.alpha.black[100],
                                  0.1
                                )}`,
                            pl: { md: 2, xs: 0 },
                          }}
                        >
                          <RenderItem
                            flightData={item}
                            dictionaries={dictionaries}
                            dataIndex={index}
                            onShowDetailClick={() => {
                              if (showDetails?.includes(index)) {
                                setShowDetails(
                                  showDetails?.filter((ele) => ele != index)
                                );
                              } else {
                                setShowDetails([...showDetails, index]);
                              }
                            }}
                            showDetail={showDetails?.includes(index)}
                            oneway={model?.type === "oneway"}
                            cityAirportDictionaries={cityAirportDictionaries}
                            onShowCancellationDescription={() =>
                              setFreeCancellation(true)
                            }
                            onHowToBookClick={() =>
                              setShowBookingStepsModal(true)
                            }
                            onBookClick={onBookClick}
                            onCallClick={onCallClick}
                          />
                          <Box
                            sx={{
                              height: showDetails?.includes(index) ? "auto" : 0,
                              transition: "all",
                              transitionDuration: 0.8,
                              pb: showDetails?.includes(index) ? 3 : 0,
                            }}
                          >
                            {showDetails?.includes(index) && (
                              <RenderDetail
                                flightData={item}
                                travelClass={model?.travelClass}
                                dataIndex={index}
                                oneway={model?.type === "oneway"}
                                dictionaries={dictionaries}
                                cityAirportDictionaries={
                                  cityAirportDictionaries
                                }
                                showDetail={showDetails?.includes(index)}
                                onShowCancellationDescription={() =>
                                  setFreeCancellation(true)
                                }
                                onBookClick={onBookClick}
                                onCallClick={onCallClick}
                              />
                            )}
                          </Box>
                        </Box>
                        {filteredData?.slice(
                          currentPageCount,
                          currentPageCount + perPageCount
                        )?.length <= 2 && index === 1 ? (
                          <SpecialDealCard />
                        ) : (
                          filteredData?.slice(
                            currentPageCount,
                            currentPageCount + perPageCount
                          )?.length < 2 && <SpecialDealCard />
                        )}
                      </>
                    );
                  })
              )}
              <Modal
                open={freeCancellation}
                onClose={() => setFreeCancellation(false)}
              >
                <Box sx={{ p: 5 }}>
                  Book now and if your plans change, you can cancel for no
                  charge by calling us within 3 hours or for fee within 12 hours
                  of booking.
                </Box>
              </Modal>
              <Box
                sx={{
                  mt: 6,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <FlightPagination
                  count={totalPage > 0 ? totalPage : 1}
                  page={page}
                  setPage={(value) => {
                    setPage(value);
                    setShowDetails([]);
                  }}
                />
              </Box>
            </Box>
          </Stack>
        )}
      </Container>
      <BookingModal
        visible={showBookingModal}
        setVisible={setShowBookingModal}
      />
      <CallModal visible={showCallModal} setVisible={setShowCallModal} />
      <BookingStepsModal
        visible={showBookingStepsModal}
        setVisible={setShowBookingStepsModal}
      />
      <Faqs faqs={faqsFlights} />
    </Box>
  );
};

const mapStateToProps = ({
  flights: { list, filteredList },
  searchModel,
  tempModel,
  locations: { cityAirportDictionaries,  },
  showDetails,
  activeTab,
  filterModal,
}: reducerProps) => ({
  filteredData: filteredList?.data,
  data: list?.data,
  dictionaries: list?.dictionaries,
  model: searchModel,
  tempModel,
  cityAirportDictionaries,
  showDetails,
  activeTab,
  filterModal,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: (payload: any) => dispatch(setActiveTab(payload)),
  setShowDetails: (payload: any) => dispatch(setShowDetails(payload)),
  setFilterModal: (payload: any) => dispatch(setFilterModal(payload)),
  setRoute: (payload: any) => dispatch(setCurrentRoute(payload)),
  setLoader: (payload: any) => dispatch(setLoader(payload)),
  removeLoader: () => dispatch(removeLoader()),
  setBookingFlight: (payload: any) => dispatch(setBookingFlight(payload)),
  fetchCityAirportDictionaries: (payload: any) =>
    dispatch(fetchCityAirportDictionaries(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

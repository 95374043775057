import {
  Box,
  Container,
  Link,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from "@mui/material";
import { PrimaryButton } from "src/components/Buttons";
import { contactInfos, links1, socialMedia } from "./constants";
import Banner from "src/components/Banner";
import Logo from "src/components/LogoWhite";
import SectionHeader, { HeaderSpan } from "src/components/SectionHeader";
import GoogleMap from "src/components/GoogleMap";

const Column = ({ children, sx }: { children: any; sx?: SxProps }) => (
  <Box sx={{ width: { md: "23%", xs: "100%" }, mr: { md: 1, xs: 0 }, ...sx }}>
    {children}
  </Box>
);
const Footer = () => {
  const theme = useTheme();
  return (
    <Banner
      url="/assets/img/footer-bg.webp"
      colorSx={{
        backgroundImage: "unset",
        py: 0,
        mt: 0,
      }}
      container={false}
    >
      <Container
        sx={{
          py: 3,
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: "space-between",
          }}
        >
          <Column sx={{ width: { md: "15%" } }}>
            <SectionHeader
              sx={{ color: "white", textAlign: "left", fontSize: 25, mb: 0 }}
            >
              Quick <HeaderSpan>Links</HeaderSpan>
            </SectionHeader>
            <Box
              component="ul"
              sx={{ listStyle: "none", paddingLeft: 0, position: "relative" }}
            >
              {links1?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    component="li"
                    sx={{
                      display: "flex",
                      mb: 1,
                    }}
                  >
                    <Link
                      sx={{ color: "white", textDecoration: "none" }}
                      href={item?.value}
                    >
                      {item?.label}
                    </Link>
                  </Box>
                );
              })}
              <Box
                sx={{
                  width: 180,
                  mt: { md: 3 },
                  position: { xs: "absolute", md: "relative" },
                  top: 0,
                  right: 0,
                }}
              >
                <Logo />
              </Box>
            </Box>
          </Column>
          <Column sx={{ width: { md: "30%" }, mr: { md: 3, xs: 0 } }}>
            <SectionHeader
              sx={{ color: "white", textAlign: "left", fontSize: 25, mb: 0 }}
            >
              About <HeaderSpan>Us</HeaderSpan>
            </SectionHeader>
            <Box component="p" sx={{ color: "white" }}>
              Welcome to Millwoods, your one-stop destination for seamless and
              memorable travel experiences. At Millwoods, we go beyond being
              just a flight booking company; we are your travel companion,
              dedicated to turning your journeys into exciting adventures.
            </Box>
            <PrimaryButton
              component={Link}
              href="/about-us"
              style={{ textTransform: "none" }}
            >
              More info
            </PrimaryButton>
            <Stack
              direction="row"
              spacing={1}
              component="ul"
              sx={{ listStyle: "none", paddingLeft: 0 }}
            >
              {socialMedia?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    component="li"
                    sx={{
                      display: "flex",
                      mb: 1,
                    }}
                  >
                    <Link
                      sx={{ color: "white", textDecoration: "none" }}
                      href={item?.value}
                      target="_blank"
                    >
                      {item?.icon}
                    </Link>
                  </Box>
                );
              })}
            </Stack>
          </Column>
          {contactInfos?.map(({ title, info }, contactIndex) => (
            <Column sx={{ ml: { md: contactIndex } }} key={contactIndex}>
              <SectionHeader
                sx={{ color: "white", textAlign: "left", fontSize: 25, mb: 0 }}
              >
                {title?.map((t, tIndex) =>
                  tIndex % 2 == 0 ? t : <HeaderSpan>{t}</HeaderSpan>
                )}
              </SectionHeader>
              <Box component="ul" sx={{ listStyle: "none", paddingLeft: 0 }}>
                {info?.map((item, index) => {
                  let props = item?.value ? { href: item?.value } : {};
                  return (
                    <Box
                      key={index}
                      component="li"
                      sx={{
                        mb: 1,
                      }}
                    >
                      <Box sx={{ display: "flex" }}>
                        {item?.icon}
                        <Box
                          component={
                            item?.value && !item?.address ? "a" : "span"
                          }
                          sx={{
                            color: "white",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          {...props}
                        >
                          {item?.label}
                        </Box>
                      </Box>
                      {item?.address && (
                        <GoogleMap
                          sx={{
                            ml: 0,
                            mt: contactIndex === 0 ? 1 : 2.5,
                            height: 200,
                            width: "100%",
                            borderRadius: 0.5,
                          }}
                          src={item?.value}
                        />
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Column>
          ))}
        </Stack>
      </Container>
      <Stack
        direction="row"
        justifyContent="center"
        py={1}
        borderTop={1}
        borderColor="white"
      >
        <Typography sx={{ color: "white", mr: 0.5 }}>
          Copyright © {new Date()?.getFullYear()} | Powered by
        </Typography>
        <Link
          href="https://cyberace.ca"
          sx={{ textDecoration: "none" }}
          target="_blank"
        >
          Cyber Ace
        </Link>
      </Stack>
    </Banner>
  );
};

export default Footer;

import ReactDOM from "react-dom/client";
import "nprogress/nprogress.css";
import 'react-image-crop/src/ReactCrop.scss'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import App from "src/App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

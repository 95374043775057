import type { PayloadAction } from "@reduxjs/toolkit";
import {
  RESET_SEARCH_MODEL,
  RESET_TEMP_MODEL,
  SET_ACTIVE_TAB,
  SET_FILTER_MODAL,
  SET_GOOGLE_REVIEWS,
  SET_SEARCH_MODEL,
  SET_SHOW_DETAILS,
  SET_TEMP_MODEL,
} from "./types";
import { addDays } from "date-fns";
import { flightSearchProps } from "src/interfaces";
import { reviewsRoot } from "src/interfaces";
import { reviews } from "src/mockData/reviews";

const initialeSearchModelState: flightSearchProps = {
  type: "round",
  adults: 1,
  children: 0,
  infants: 0,
  travelClass: "ECONOMY",
  origin: "",
  originName: "",
  destination: "",
  destinationName: "",
  nonStop: false,
  departureDate: addDays(new Date(), 7),
  returnDate: addDays(new Date(), 14),
  maxNumberOfConnections: 2,
  includedCheckedBagsOnly: false,
  includedCarrierCodes: [],
  duration: 0,
  layover: [0, 0],
  outbound: [0, 0],
  filtering: false,
  localFiltering: false,
  searching: null,
};
export const searchModel = (
  state: flightSearchProps = initialeSearchModelState,
  action: PayloadAction<flightSearchProps> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_SEARCH_MODEL: {
      return payload;
    }
    case RESET_SEARCH_MODEL: {
      return initialeSearchModelState;
    }
    default: {
      return state;
    }
  }
};

export const tempModel = (
  state: flightSearchProps = initialeSearchModelState,
  action: PayloadAction<flightSearchProps> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_TEMP_MODEL: {
      return payload;
    }
    case RESET_SEARCH_MODEL: {
      return initialeSearchModelState;
    }
    case RESET_TEMP_MODEL: {
      return initialeSearchModelState;
    }
    default: {
      return state;
    }
  }
};

export const filterModal = (
  state: boolean = false,
  action: PayloadAction<boolean> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_FILTER_MODAL: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const activeTab = (
  state: "price" | "time" | "price&time" = "price",
  action: PayloadAction<"price" | "time" | "price&time"> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_ACTIVE_TAB: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const showDetails = (
  state: number[] = [],
  action: PayloadAction<number[]> = null
) => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_SHOW_DETAILS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

export const googleReviews = (
  state: reviewsRoot = reviews,
  action: PayloadAction<any> = null
): reviewsRoot => {
  const type = action?.type;
  const payload = action?.payload;
  switch (type) {
    case SET_GOOGLE_REVIEWS: {
      return payload;
    }
    default: {
      return state;
    }
  }
};

import { Box, Container, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useSearchParams } from 'react-router-dom'
import Breadcrumbs from 'src/components/Breadcrumb'
import SectionHeader, { HeaderSpan } from 'src/components/SectionHeader'

const Component = () => {
    const location = useLocation()
    const theme = useTheme()
    const pathname = location?.pathname;
    const blogUrl = pathname?.substring(pathname?.lastIndexOf("/") + 1, pathname?.length)
    const HeadCallback = useCallback(() => {
        return (
            <Helmet>
                <title>{blog?.title}</title>
                <meta name="theme-color" content={theme?.colors?.secondary?.main} />
            </Helmet>
        );
    }, [pathname]);

    const [data, setData] = useState([])
    useEffect(() => {
        fetch('/blogs.json',).then(res => {
            if (res.status === 200) {
                res.json().then(responseData => {
                    setData(responseData)
                })
            }
        })
    }, [])
    const blog = data?.find(ele => ele?.url == blogUrl)
    const title = blog?.title?.split(":")
    
    useEffect(() => {
        if (blog?.title) {
            let tabTitle = document.getElementById('tab-head-title')
            tabTitle.innerText = blog?.title
        }
    }, [blog?.title])

    return (
        <>
            <Box>
                <HeadCallback />
                <Breadcrumbs
                    title={blog?.breadcrum}
                    banner={{
                        md: "/assets/img/vacations-banner.webp",
                        xs: "/assets/img/vacations-banner-mobile.webp",
                    }}
                />
                <Container sx={{ my: 2, mb: 6 }} maxWidth="lg">
                    <SectionHeader sx={{ color: theme?.colors?.primary?.main, fontWeight: 'bold', textAlign: 'start' }} component="h1">
                        {title?.length > 1 && <HeaderSpan  sx={{ color: theme?.colors?.secondary?.main }}>{title?.slice(1, title?.length)?.join(" ")}</HeaderSpan>}
                    </SectionHeader>
                    <Typography sx={{ width: '100%' }} component="img" src={blog?.img} />
                    <Box component="div" sx={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: blog?.content }} />
                </Container>
            </Box>
        </>
    )
}

export default Component
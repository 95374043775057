import {
  Button as DefaultButton,
  ButtonProps,
  useTheme,
  IconButton,
  IconButtonProps,
} from "@mui/material";

export const PrimaryButton = (props: ButtonProps) => {
  const theme = useTheme();
  const finalProps = {
    ...props,
    variant: props?.variant ? props?.variant : "contained",
  };
  return (
    <DefaultButton
      {...finalProps}
      sx={{
        backgroundColor:
          finalProps?.variant === "contained"
            ? theme?.colors?.primary.main
            : "none",
        color: "white",
        px: 3,
        py: 1,
        ...props.sx,
      }}
    >
      {props?.children}
    </DefaultButton>
  );
};

export const SecondaryButton = (props: ButtonProps) => {
  const theme = useTheme();
  const finalProps = {
    ...props,
    variant: props?.variant ? props?.variant : "contained",
  };
  return (
    <DefaultButton
      {...finalProps}
      sx={{
        backgroundColor:
          finalProps?.variant === "contained"
            ? theme?.colors?.secondary.main
            : "none",
        color: "white",
        px: 3,
        py: 1,
        ...props.sx,
      }}
    >
      {props?.children}
    </DefaultButton>
  );
};

export const PrimaryIconButton = (props: IconButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
      color="primary"
      {...props}
      sx={{
        backgroundColor: theme?.colors?.primary.main,
        ...props.sx,
      }}
    >
      {props?.children}
    </IconButton>
  );
};

export const SecondaryIconButton = (props: IconButtonProps) => {
  const theme = useTheme();
  return (
    <IconButton
      {...props}
      sx={{
        backgroundColor: theme?.colors?.secondary?.main,
        ...props.sx,
      }}
    >
      {props?.children}
    </IconButton>
  );
};

import {
  Box,
  Container,
  useTheme,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { PrimaryButton } from "src/components/Buttons";
import Modal from "src/components/Modal";
import { inputs } from "./constants";
import { createFlightOrder, setAlert } from "src/store";
import { reducerProps } from "src/interfaces";
import { connect } from "react-redux";
import { emailValidator } from "src/helpers";

const Component = ({
  visible,
  setVisible,
  booking,
  createOrder,
}: {
  visible: boolean;
  setVisible;
  booking: any;
  createOrder: (payload: any) => void;
}) => {
  const theme = useTheme();
  const [model, setModel] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const onChange = (event: any, type?: string) => {
    const name = event?.target?.name;
    setErrors({ ...errors, [name]: "" });
    let value: string = event?.target?.value;

    if (type === "numberOnly") {
      value = value?.replace(/[^0-9]/g, "");
    }
    if (type === "charOnly") {
      value = value?.replace(/[^A-Z,a-z," "]/g, "");
    }
    let newParams: any = {};
    if (name == "countryCode") {
      newParams = { stateName: "" };
    }
    setModel({
      ...model,
      [name]: value,
      ...newParams,
    });
  };

  const onSubmit = async (event) => {
    event?.preventDefault();
    if (!emailValidator(model?.email)) {
      setErrors({ email: "Please enter valid email ID" });
      return;
    }
    createOrder({
      data: { type: "flight-order", flightOffers: [booking] },
      billingDetailModel: model,
    });
  };

  const callNowCalgoryIcon = (
    <Typography
      sx={{ width: "100%" }}
      component="img"
      src="/assets/icons/callnow-calgary.svg"
    />
  );

  const CallNowButtonCalgory = () => {
    return (
      <PrimaryButton
        variant="text"
        href="tel:+1 403-200-0222"
        sx={{
          p: "0 !important",
          mt: 0.5,
        }}
      >
        {callNowCalgoryIcon}
      </PrimaryButton>
    );
  };

  const callNowEdomontonIcon = (
    <Typography
      sx={{ width: "100%" }}
      component="img"
      src="/assets/icons/callnow-edmonton.svg"
    />
  );

  const CallNowButtonEdomonton = () => {
    return (
      <PrimaryButton
        variant="text"
        href="tel:+1 780-244-3222"
        sx={{ p: "0 !important", mt: 0.5 }}
      >
        {callNowEdomontonIcon}
      </PrimaryButton>
    );
  };

  return (
    <Modal
      sx={{
        width: { md: "35%" },
        mx: "auto",
        display: "flex",
        justifyContent: "center",
      }}
      open={visible}
      onClose={() => setVisible(false)}
    >
      <Box
        sx={{
          backgroundColor: theme?.colors?.secondary?.main,
          py: 5,
          px: 3,
          mx: "auto",
          border: `2px solid ${theme?.colors?.alpha?.white[100]}`,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              borderRadius: 10,
              textAlign: "center",
              mx: "auto",
              // backgroundColor: "#309CFF",
              color: "white",
              fontSize: { md: 25, xs: 18 },
            }}
          >
            Get Special Phone Deals!
          </Box>
          <Box
            sx={{
              color: "white",
              textAlign: "center",
              mb: 1.5,
            }}
          >
            Fill up the form below and we will call you
          </Box>
          <Box onSubmit={onSubmit} component="form">
            <Box
              sx={{
                ".MuiOutlinedInput-root": {
                  borderColor: "white",
                  borderRadius: 0,
                  backgroundColor: "white",
                },
              }}
            >
              {inputs?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      mb: { md: 3, xs: 1.5 },
                    }}
                  >
                    <Box component="label" sx={{ color: "white" }}>
                      {item?.label}
                    </Box>
                    <TextField
                      {...item}
                      key={index}
                      name={item?.key}
                      type={item?.type || "text"}
                      sx={{
                        width: "100%",
                        mt: 0.5,
                      }}
                      label=""
                      placeholder={item?.label}
                      value={model[item?.key]}
                      onChange={(event) => onChange(event, item?.type)}
                      inputProps={{
                        ...item,
                        maxLength: item?.maxLength,
                        minLength: item?.minLength,
                        style: { height: 5 },
                      }}
                    />
                    <Typography color="red" component="span">
                      {errors[item?.key]}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <PrimaryButton type="submit" sx={{ mt: 1.5, width: "100%" }}>
              Submit Details
            </PrimaryButton>
          </Box>
          <Box sx={{ mt: 2, position: "relative" }}>
            <Box
              sx={{
                color: "white",
                textAlign: "center",
                zIndex: 1,
                position: "relative",
                width: { lg: "20%", md: "30%", xs: "30%" },
                mx: "auto",
                backgroundColor: theme?.colors?.secondary?.main,
              }}
            >
              Or Call Us
            </Box>
            <Box
              sx={{
                height: 2,
                width: "100%",
                backgroundColor: theme?.colors?.secondary?.light,
                position: "absolute",
                bottom: { md: "50%", xs: "45%" },
                zIndex: 0,
              }}
            />
          </Box>
          <Stack direction="row" width="100%" spacing={2} mt={1}>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ color: "#83C4FF" }}>Calgary Office</Typography>
              <CallNowButtonCalgory />
            </Box>
            <Box sx={{ width: "50%" }}>
              <Typography sx={{ color: "#83C4FF" }}>Edmonton Office</Typography>
              <CallNowButtonEdomonton />
            </Box>
          </Stack>
        </Container>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({
  flights: { booking },
  searchModel,
}: reducerProps) => ({
  model: searchModel,
  booking,
});

const mapDispatchToProps = (dispatch) => ({
  setAlert: (payload: any) => dispatch(setAlert(payload)),
  createOrder: (payload: any) => dispatch(createFlightOrder(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Component);

import { Box, Stack, SxProps, useTheme } from "@mui/material";

const SpecialDealCard = ({
  title = "More than 80% repeat clients",
  description = "Let us get you a deal too",
  containerSx,
}: {
  title?: string;
  description?: string;
  containerSx?: SxProps;
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        backgroundColor: "#d0eeff",
        alignItems: "center",
        my: 1,
        ...containerSx,
      }}
      direction="row"
    >
      <Box
        sx={{
          width: { md: "80%", xs: "70%" },
          px: { md: 3, xs: 1 },
          py: 1,
        }}
      >
        <Box component="h2" sx={{ color: theme?.colors?.secondary?.dark }}>
          {title}
        </Box>
      </Box>
      <Box
        sx={{
          color: theme?.colors?.alpha?.white[100],
          backgroundImage: `url(/assets/icons/strap.svg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: { md: "35%", xs: "60%" },
          textAlign: "center",
          pl: { md: 5, xs: 7 },
          py: 1.5,
        }}
      >
        {description}
      </Box>
    </Stack>
  );
};

export default SpecialDealCard;

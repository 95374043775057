import { Box, BoxProps as DefaultProps } from "@mui/material";

interface BoxProps extends DefaultProps {
  src: string;
}
const GoogleMap = (props: BoxProps) => {
  return (
    <Box
      component="iframe"
      width={{ md: "80%", xs: "100%" }}
      height="600"
      style={{ border: 0 }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      {...props}
      sx={{ ml: 4, mt: 1, ...props?.sx }}
    ></Box>
  );
};

export default GoogleMap;

import { Box, Stack, SxProps, useTheme } from "@mui/material";
import React from "react";

const LayoverCard = ({
  layover,
  layoverAirport,
  layoverTime,
  containerSx,
}: {
  layover: number;
  layoverAirport: string;
  layoverTime: string;
  containerSx?: SxProps;
}) => {
  const theme = useTheme();
  return (
    <Stack
      sx={{
        width: { md: "75%", xs: "95%" },
        ml: { md: "auto" },
        mr: { md: "21%", xs: "2%" },
        backgroundColor: "#d0eeff",
        borderRadius: 1,
        px: 1,
        py: 0.5,
        my: 2,
        alignItems: "center",
        ...containerSx,
      }}
      direction="row"
    >
      <Box
        sx={{
          width: { md: "80%", xs: "70%" },
          pl: { md: 3, xs: 1 },
          py: 0.5,
        }}
      >
        <Box sx={{ mb: layover >= 480 ? 0.5 : 0 }}>
          Layover in {layoverAirport}
        </Box>
        {layover >= 480 && (
          <Box
            component="span"
            sx={{
              borderRadius: 3,
              width: "auto",
              px: 3,
              py: 0.5,
              color: theme?.colors?.alpha?.white[100],
              backgroundColor: theme?.colors?.error?.main,
            }}
          >
            Long Layoff
          </Box>
        )}
      </Box>
      <Box
        sx={{
          textAlign: "right",
          width: { md: "20%", xs: "30%" },
        }}
      >
        <Box>Layover</Box>
        <Box
          sx={{
            color: theme?.colors?.secondary?.dark,
            fontWeight: "bold",
          }}
        >
          {layoverTime}
        </Box>
      </Box>
    </Stack>
  );
};

export default LayoverCard;

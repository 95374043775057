import { Box, Stack, SxProps, useTheme } from "@mui/material";
import React from "react";
import LuggageIcon from "@mui/icons-material/Luggage";

const Component = ({
  quantity,
  weight,
  unit,
  iconSx,
  containerSx
}: {
  quantity?;
  weight?;
  unit?;
  iconSx?: SxProps;
  containerSx?: SxProps;
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{
        color: theme?.colors?.secondary?.main,
        ...containerSx,
      }}
    >
      <LuggageIcon color="secondary" fontSize="small" sx={iconSx} />
      <Box sx={{ fontSize: 12, ml: 0.5 }}>
        {quantity && (
          <Box sx={{}}>
            {quantity}
            {quantity > 1 ? " Checked bags " : " Checked bag"}
          </Box>
        )}
        {weight && (
          <Box sx={{}}>
            {weight} {unit} Only
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default Component;

import { Box, BoxProps, Container, Stack, TextField } from "@mui/material";
import { PrimaryButton } from "src/components/Buttons";
import SectionHeader from "../../../components/SectionHeader";
import SectionContainer from "../../../components/SectionContainer";
import PaperIcon from "../../../components/PaperIcon";

const Column = (props: BoxProps) => (
  <Box sx={{ width: { md: "30%", xs: "100%" }, ...props.sx }} {...props}>
    {props?.children}
  </Box>
);
const Subscribe = () => {
  return (
    <SectionContainer sx={{ mb: "3rem" }}>
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Stack
          direction="row"
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Column
            sx={{ width: { md: "20%", xs: "100%", textAlign: "center" } }}
          >
            <Box
              component="img"
              src="/assets/icons/subscribe-graphics.svg"
              sx={{
                height: 140,
                objectFit: "contain",
              }}
            />
          </Column>
          <Column sx={{ width: { md: "70%", xs: "100%" } }}>
            <SectionHeader
              component="h3"
              sx={{ fontSize: "normal", textAlign: "start" }}
            >
               Enter your email below to get latest deals in your inbox:
            </SectionHeader>
            <Box component="form">
              <Box
                sx={{
                  ".MuiOutlinedInput-root": {
                    borderColor: "white",
                    borderRadius: 0,
                    backgroundColor: "white",
                  },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  name="email"
                  type={"text"}
                  sx={{
                    width: { md: "80%", xs: "100%" },
                    mr: "1%",
                  }}
                  label=""
                  placeholder={"Your Email Address Please"}
                  inputProps={{ style: { height: 10 } }}
                />
                <PrimaryButton sx={{ width: { md: "20%", borderRadius: 0 } }}>
                  Submit
                </PrimaryButton>
              </Box>
            </Box>
          </Column>
        </Stack>
      </Container>
      <PaperIcon
        src="/assets/icons/paper-plane.svg"
        sx={{
          top: -250,
          height: 500,
        }}
      />
    </SectionContainer>
  );
};

export default Subscribe;


import React from 'react';
import './style.css';

const FloatingWhatsApp = () => {
    const phoneNumber = '+14036870111'; 
  
    const handleClick = () => {
      window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };
  
    return (
      <div className="floating-whatsapp" onClick={handleClick}>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png"
          alt="WhatsApp"
        />
      </div>
    );
  };
  
  export default FloatingWhatsApp;
  
import {
  Checkbox as DefaultCheckbox,
  CheckboxProps,
  Slider as DefaultSlider,
  SliderProps as DefaultSliderProps,
  styled,
  Typography,
  Box,
} from "@mui/material";

export const Checkbox = (props: CheckboxProps) => {
  return <DefaultCheckbox sx={{ p: 0.7, ml: 0.5 }} {...props} />;
};

const StyledSlider = styled(DefaultSlider)(({ theme }) => ({
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  "& .MuiSlider-thumb": {
    height: 10,
    width: 10,
    borderRadius: 5,
  },
}));

export const TinyText = styled(Typography)({
  fontSize: "0.75rem",
  opacity: 0.8,
  fontWeight: 500,
  letterSpacing: 0.2,
});

interface SliderProps extends DefaultSliderProps {
  tinylabel?: "on" | "off";
}
export const Slider = (props: SliderProps) => {
  const sliderValue: number | number[] =
    props?.value || props?.defaultValue || [];
  const tinyTextMin =
    typeof sliderValue === "object" && sliderValue?.length > 0
      ? sliderValue[0]
      : "";
  const tinyTextMax =
    typeof sliderValue === "object" && sliderValue?.length > 1
      ? sliderValue[1]
      : "";

  const minValue =
    typeof sliderValue === "object" && sliderValue?.length > 0
      ? sliderValue[0]
      : "";
  const maxValue =
    typeof sliderValue === "object" &&
    sliderValue?.length > 1 &&
    (sliderValue[1] as any) !== "Any"
      ? sliderValue[1]
      : (sliderValue[1] as any) === "Any"
      ? props?.max
      : "";
      
  return (
    <Box style={{ position: "relative" }}>
      <StyledSlider
        sx={{ ml: 0.5 }}
        {...props}
        value={
          typeof sliderValue === "number"
            ? sliderValue
            : ([minValue, maxValue] as number[])
        }
      />
      {props?.tinylabel === "on" && sliderValue && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: -2,
            mb: 1,
          }}
        >
          {typeof sliderValue === "number" ? (
            <TinyText>{sliderValue}</TinyText>
          ) : (
            <>
              <TinyText>{tinyTextMin}</TinyText>
              <TinyText>{tinyTextMax}</TinyText>
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

import { useContext } from "react";

import {
  Box,
  alpha,
  lighten,
  IconButton,
  Tooltip,
  styled,
  useTheme,
  Container,
} from "@mui/material";
import { SidebarContext } from "src/contexts/SidebarContext";

import Logo from "src/components/LogoDark";
import { useLocation } from "react-router";
import SearchArea from "src/views/components/searchArea";
import HeaderMenu from "../../components/Header/Menu";
import SocialMedia from "../../components/Header/SocialMedia";

const Header = () => {
  const location = useLocation();
  const pathname = location?.pathname;
  const isHome = pathname === "/";
  const isMobile = document?.body?.clientWidth < 991;
  const headerBackground = isHome
    ? "background-color:transparent"
    : isMobile
    ? `background-image:url("/assets/img/search-header-bg-3.webp");background-size:cover`
    : `background-image:url("/assets/img/search-header-bg-2.webp");background-size:cover`;
  const HeaderWrapper = styled(Box)(
    ({ theme }) => `
          color: ${theme?.user?.header.textColor};
          right: 0;
          z-index: 6;
          ${headerBackground};
          // backdrop-filter: blur(3px);
          position: ${isHome ? "absolute" : "relative"};
          justify-content: space-between;
          width: 100%;
          // @media (min-width: ${theme.breakpoints.values.lg}px) {
          //     left: ${theme?.user?.sidebar.width};
          //     width: auto;
          // }
  `
  );
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();

  return (
    <HeaderWrapper
      display={isHome ? "flex" : "block"}
      alignItems="center"
      sx={{
        height: { md: isHome ? theme?.user?.header?.height : 230 },
        position: !isHome && "fixed",
        top: 0,
        boxShadow:
          pathname === "/"
            ? "unset"
            : theme.palette.mode === "dark"
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(
                theme.colors.alpha.secondary[100],
                0.2
              )}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.secondary[100],
                0.1
              )}`,
      }}
    >
      <Box
        sx={{
          backgroundColor: {
            md: !isHome && theme?.colors?.alpha?.white[100],
            position: "relative",
          },
          width: "100%",
        }}
      >
        <Container
          maxWidth={"lg"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            sx={{ mt: isHome && 2, width: { md: "15%", xs: "25%" } }}
          >
            <Logo width={'100%'} />
          </Box>
          <HeaderMenu color={!isHome && theme?.colors?.secondary?.main} />
          <Box sx={{ display: { md: !isHome && "block", xs: "none" } }}>
            <SocialMedia />
          </Box>
          <Box sx={{ display: { md: "none" } }}>
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                <Box
                  sx={{ width: 25 }}
                  component="img"
                  src="/assets/icons/menu.svg"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Container>
      </Box>
      {!isHome && !isMobile && (
        <Box
          sx={{
            width: "100%",
            display: { md: "block", xs: "none" },
            backgroundColor: theme?.colors?.alpha?.secondary[50],
          }}
        >
          <SearchArea />
        </Box>
      )}
    </HeaderWrapper>
  );
};

export default Header;

import MailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import AddressIcon from "@mui/icons-material/Map";
import FacebookIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";

export const links1 = [
  {
    label: "Search Flights",
    value: "/flights",
  },
  {
    label: "Contact Us",
    value: "/contact-us",
  },
  {
    label: "About Us",
    value: "/about-us",
  },
  {
    label: "Blog",
    value: "/blog",
  },
  {
    label: "Terms",
    value: "/terms",
  },
  // {
  //   label: "Booking Rules",
  //   value: "/booking-rules",
  // },
  // {
  //   label: "Terms & Conditions",
  //   value: "/terms",
  // },
  // {
  //   label: "Privacy Policy",
  //   value: "/privacy-policy",
  // },
];
export * from "src/common/constants";

export const socialMedia = [
  {
    icon: <FacebookIcon />,
    value: "https://www.facebook.com/mymillwoodstravels/",
  },
  {
    icon: <InstagramIcon />,
    value: "https://www.instagram.com/millwoodstravel/",
  },
];

export const cards = [
  {
    title: "Save upto 30%",
    description: "Incredible saving on holidays",
    icon: "/assets/icons/money.webp",
    backgroundColor: "#E0EDFF",
  },
  {
    title: "Worldwide coverage",
    description: "Choose from over 200,000 destinations",
    icon: "/assets/icons/world.webp",
    backgroundColor: "#FFF2DA",
  },
  {
    title: "Confidence & convenience",
    description: "Flexible policies, easy cancellations",
    icon: "/assets/icons/confidence.png",
    backgroundColor: "#FFD3D3",
  },
];

import { changePasswordProps, loginProps, resetPasswordProps, userValidationProps, verifyEmailActionProps } from "../../interfaces";
import {
  HANDLE_LOGIN,
  SET_AUTH_USER,
  REMOVE_AUTH_USER,
  HANDLE_FETCH_AMADEUS_TOKEN,
  REMOVE_AMADEUS_TOKEN,
  SET_AMADEUS_TOKEN,
  HANDLE_RESET_PASSWORD,
  HANDLE_VERIFY_RESET_PASSWORD,
  HANDLE_CHANGE_PASSWORD,
  HANDLE_FORGOT_PASSWORD,
  HANDLE_USER_VALIDATION,
  HANDLE_USER_VERIFY_EMAIL,
  HANDLE_USER_VERIFICATION_EMAIL_RESEND,
} from "./types";

export const fetchAmadeusToken = () => {
  return {
    type: HANDLE_FETCH_AMADEUS_TOKEN,
  };
};

export const login = (payload: loginProps) => {
  return {
    type: HANDLE_LOGIN,
    payload,
  };
};
export const userVerificationEmailResend = () => {
  return {
    type: HANDLE_USER_VERIFICATION_EMAIL_RESEND,
  };
};
export const userVerifyEmail = (payload: verifyEmailActionProps) => {
  return {
    type: HANDLE_USER_VERIFY_EMAIL,
    payload,
  };
};

export const userValidation = (payload: userValidationProps) => {
  return {
    type: HANDLE_USER_VALIDATION,
    payload,
  };
};
export const forgotPassword = (payload: string) => {
  return {
    type: HANDLE_FORGOT_PASSWORD,
    payload,
  };
};
export const verifyResetPassword = (payload: verifyEmailActionProps) => {
  return {
    type: HANDLE_VERIFY_RESET_PASSWORD,
    payload,
  };
};
export const resetPassword = (payload: resetPasswordProps) => {
  return {
    type: HANDLE_RESET_PASSWORD,
    payload,
  };
};
export const changePassword = (payload: changePasswordProps) => {
  return {
    type: HANDLE_CHANGE_PASSWORD,
    payload,
  };
};
export const setAmadeusToken = (payload: any) => {
  return {
    type: SET_AMADEUS_TOKEN,
    payload,
  };
};
export const removeAmadeusToken = () => {
  return {
    type: REMOVE_AMADEUS_TOKEN,
  };
};
export const setAuthUser = (payload: any) => {
  return {
    type: SET_AUTH_USER,
    payload,
  };
};
export const removeAuthUser = () => {
  return {
    type: REMOVE_AUTH_USER,
  };
};

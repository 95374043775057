import { Box, Link, Typography, useTheme } from "@mui/material";

const BlogCard = ({ item, index }) => {
  const theme = useTheme();
  return (
    <Box
      component={Link}
      href={"/blog/article/" + item?.url}
      key={index}
      sx={{
        cursor: "pointer",
        backgroundImage: `url(${item?.img})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        width: { md: "48%", xs: "100%" },
        py: { md: 11.9, xs: 5 },
      }}
    >
      <Typography
        component="div"
        sx={{
          // backgroundColor: theme?.colors?.alpha?.black[50],
          width: "100%",
          position: "relative",
          p: 5,
        }}
        color="white"
      >
        {/* {item?.title} */}
      </Typography>
    </Box>
  );
};

export default BlogCard;

import { addMinutes, differenceInMinutes } from "date-fns";
import moment from "moment";
import { Price2, flightPriceProps, travelerPricingProps } from "src/interfaces";

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()] as any[];
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}
export const camalCaseToTitle = (text) => {
  const result = text?.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export function formatCardNumber(value) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  var matches = v?.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];
  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }
  if (parts.length) {
    return parts.join("-");
  } else {
    return value;
  }
}

export function formatExpiryDate(value) {
  var v = value?.replace(/\s+/g, "")?.replace(/[^0-9]/gi, "");
  var matches = v?.match(/\d{2,6}/g);
  var match = (matches && matches[0]) || "";
  var parts = [];
  if (match?.length > 2) {
    parts[0] = match.substring(0, 2);
    parts[1] = match.substring(2, match.length);
  }
  if (value?.length === 3 && value?.includes("/")) {
    return match?.substring(0, 2);
  }
  if (parts.length) {
    return parts.join("/");
  } else {
    return value;
  }
}

export const addTax = (priceData: flightPriceProps): flightPriceProps => {
  let tax = 5;
  let newPriceData: flightPriceProps = { ...priceData };
  const baseTax = (parseFloat(priceData?.base) * tax) / 100;
  const newTotal =
    parseFloat(priceData?.total as any) + parseFloat(baseTax as any);
  const newBase = parseFloat(priceData?.base as any);
  newPriceData = {
    ...priceData,
    total: newTotal?.toFixed(2) as any,
    grandTotal: newTotal?.toFixed(2) as any,
    fees: (newTotal - newBase).toFixed(2) as any,
    base: newBase?.toFixed(2),
  };
  return newPriceData;
};

export const addTaxByTraveler = (priceData: Price2): Price2 => {
  let tax = 5;
  let newPriceData: Price2 = { ...priceData };
  const baseTax = (parseFloat(priceData?.base) * tax) / 100;
  const newTotal =
    parseFloat(priceData?.total as any) + parseFloat(baseTax as any);
  const newBase = parseFloat(priceData?.base as any);
  newPriceData = {
    ...priceData,
    total: newTotal?.toFixed(2) as any,
    fees: (newTotal - newBase).toFixed(2) as any,
    base: newBase?.toFixed(2),
  };
  return newPriceData;
};

export const convertToForm = (payload: any): FormData => {
  const keys = Object.keys(payload);
  let formData = new FormData();
  keys.map((key) => {
    formData.append(key, payload[key]);
  });
  return formData;
};

export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "INR",
});

export const timeDifference = (
  largeTime: any,
  smallTime: any,
  returnValue: "boolean" | "difference" = "boolean",
  checkEqual: boolean = false,
  datetime: boolean = false
) => {
  if (returnValue === "boolean") {
    let lh = largeTime?.split(":")[0];
    let lm = largeTime?.split(":")[1];
    let rh = smallTime?.split(":")[0];
    let rm = smallTime?.split(":")[1];
    if (checkEqual) {
      if (lh > rh) {
        return true;
      }
      if (lh == rh) {
        if (lm >= rm) {
          return true;
        }
      }
    } else {
      if (lh > rh) {
        return true;
      } else if (lh == rh) {
        if (lm > rm) {
          return true;
        }
      }
    }
  } else {
    if (datetime) {
      const value: number = differenceInMinutes(
        new Date(largeTime),
        new Date(smallTime)
      );
      return value;
    } else {
      const maxTime = largeTime?.split(":");
      const minTime = smallTime?.split(":");
      const value: number = differenceInMinutes(
        new Date(1, 1, 2023, parseInt(maxTime[0]), parseInt(maxTime[1])),
        new Date(1, 1, 2023, parseInt(minTime[0]), parseInt(minTime[1]))
      );
      return value;
    }
  }
};

export const timeLabel = (
  value: number,
  time: string,
  format: "hm" | "HH:mm" = "HH:mm"
) => {
  if (!time && format === "HH:mm") {
    return "00:00";
  }
  let label = "00 h 00 m";
  if (format == "hm") {
    const mins = value;
    var hours = mins / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    label = rhours + " h " + rminutes + " m";
    label?.replaceAll("NaN", "00");
  } else {
    if (time) {
      const minTime = time?.split(":");
      const result = addMinutes(
        new Date(1, 1, 2023, parseInt(minTime[0]), parseInt(minTime[1])),
        value
      );
      label = moment(result).format("HH:mm");
    } else {
      label = "00:00";
    }
  }
  return label;
};

export const emailValidator = (value) => {
  var validRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return String(value).toLowerCase().match(validRegex) ? true : false;
};
